.p-hairset-gallery {
  position: relative;
  @include wvw(320);
  @include hvw(263);
  @include mvw(0,auto);
  @include flex(ce,ce);

  &__title {
    @include wvw(80);
  }

  &__lead {
    width: 100%;
    text-align: center;
    @include fsvw(10);
    @include mtvw(-18);
    transform: scale(.8);
    position: absolute;
    top: 0;
    left: 0;
  }

  &__item {
    @include wvw(92);
    @include hvw(92);
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    cursor: pointer;

    @include hover {
      @include opacity(.8);
    };

    & > img {
      width: 100%;
      height: 100%;
      @include object-fit;
    }
  }

  &__item-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__item--l01 {
    @extend .p-hairset-gallery__item ;
    @include tvw(0);
    @include lvw(35);
  }

  &__item--l02 {
    @extend .p-hairset-gallery__item ;
    @include tvw(85);
    @include lvw(0);
  }

  &__item--l03 {
    @extend .p-hairset-gallery__item ;
    @include tvw(170);
    @include lvw(35);
  }

  &__item--r01 {
    @extend .p-hairset-gallery__item ;
    @include tvw(0);
    @include rvw(35);
  }

  &__item--r02 {
    @extend .p-hairset-gallery__item ;
    @include tvw(85);
    @include rvw(0);
  }

  &__item--r03 {
    @extend .p-hairset-gallery__item ;
    @include tvw(170);
    @include rvw(35);
  }
}

.p-mens-recruit {
  color: $charcoal;
  @include pvw(15,15);

  &__header {
    position: relative;
    @include pbvw(10);
  }

  &__title {
    @include fsvw(18);
    @include NotoSerif;
    font-weight: 600;
    text-align: justify;
    letter-spacing: .01em;
    // background-image: url('../img/mens-recruit/1800-per-hour.png');
    // background-position: bottom;
    // background-size: 100%;
    // background-repeat: no-repeat;
    // @include hvw(120);
  }

  &__average-wage {
    color: $accent;
    border-top: 1px solid;
    border-bottom: 1px solid;
    @include fsvw(14);
    font-weight: bold;
    @include flex(ce,ce);
    @include mtvw(10);

    em {
      font-size: 120%;
    }
  }

  &__image {
    position: absolute;
    right: 0;
    bottom: 0;
    @include wvw(80);
    @include hvw(80);
  }

  &__lead {
    @include fsvw(11);
    font-size: 11px;
    padding-top: 5px;
  }
}

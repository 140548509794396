.p-index-owners_blog {
  background-image: repeating-linear-gradient(
    -45deg,
    transparent 0,
    transparent 4px,
    rgba($truewhite,.4) 4px,
    rgba($truewhite,.4) 8px
  );
  background-color: #b2ecff;
  @include pvw(5,0);

  &__title {
    @include wvw(263);
    @include mvw(5,auto);
  }
}

.p-article-meta-profile {
  @include flex(fs,fs);
  background: $truewhite;
  border-bottom: 1px solid rgba($charcoal,.1);

  &__thumb {
    width: 31.25%;
  }

  &__detail {
    width: 68.75%;
  }

  &__name {
    @include hvw(22);
    overflow: hidden;
    @include mtvw(10);
  }

  &__name-inner {
    @include fsvw(11);
    background: $trueblack;
    color: $truewhite;
    @include wvw(220);
    padding: 0 .8em;
    position: relative;
    @include hvw(22);
    text-align: center;
    font-weight: bold;
    @include flex(ce,sb);
    line-height: 0;

    &--article-meta {
      @include wvw(130);
    }

    &::after {
      position: absolute;
      content: '';
      display: block;
      width: 0;
      height: 0;
      z-index: 3;
      @include bwvw(11);
      border-style: solid;
      border-color: $trueblack;
      border-right-color: transparent;
      top: 0;
      transform: scale(1.1);
      @include rvw(-11);
    }
  }

  &__label {
    font-size: inherit;
    transform: scale(.8);
  }

  &__lead {
    @include flex(ce,fs);
    @include fsvw(9);
    @include pvw(0,10);
    line-height: 1.6;
    @include hvw(75);
  }
}

.l-footer-button-block {
  &__button-group {
    @include flex(ce,sb);
    @include pvw(10);
  }

  &__radius-button {
    width: 49%;
    @include fsvw(12.5);
    display: block;
    @include pvw(10,0);
    line-height: 1.2;
    text-align: center;
    letter-spacing: 0;
    color: $truewhite;
    border-radius: .3em;
    @include flex(ce,ce);
    font-weight: bold;
    border: 2px solid rgba($truewhite,.2);
    text-shadow: 1px 1px 1px rgba($trueblack,.2);
  }

  &__entry {
    background: #e65a97;
  }

  &__line {
    background: #00bb00;

    .glyph {
      font-size: inherit;
      font-weight: normal;
      transform: scale(2);
      margin-right: .6em;
      text-shadow: none;
    }
  }

  &__button-container {
    @include pvw(10);

  }

  &__grad-button {
    @include gradbg(rgba($cs01,.6),rgba($cs04,.6));
    width: 100%;
    @include fsvw(16);
    display: block;
    @include pvw(10,0);
    line-height: 1.2;
    text-align: center;
    color: $truewhite;
    font-weight: bold;
    text-shadow: 1px 1px 1px rgba($trueblack,.3);
    box-shadow: .3em .3em 0 rgba($trueblack,.05);

    .glyph {
      font-size: inherit;
      font-weight: normal;
      transform: scale(1.5);
      margin-right: .8em;
      text-shadow: .5px .5px .5px rgba($trueblack,.3);
      position: relative;
      top: -.1em;
    }
  }
}

.c-nav-target {
  @include gtMD {
    height: 100%;
  };

  @include inSM {
    @include flex(ce,ce);
  };

  @include ltXS {
    display: block;
    background: $black;
    position: fixed;
    top: 60px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
  };

  @include mq {
    // height: 100%;
    display: none;
  };
}

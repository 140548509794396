@mixin blogstripe {
  background-image: repeating-linear-gradient(
    -45deg,
    transparent 0,
    transparent 4px,
    rgba($truewhite,.6) 4px,
    rgba($truewhite,.6) 8px
  );
}

.p-article {
  background-color: $white;
  @include blogstripe;

  &--erika_blog {
    background-color: #fdf1f6;
  }

  &--owners_blog {
    background-color: #eafaff;
  }

  &--archive {
    border-bottom: 4px solid $trueblack;
  }
}

.p-contact-table {
  table-layout: fixed;
  display: block;
  @include fsvw(11);

  &,tr,tbody {
    display: block;
  }

  tr {
    border-bottom: 1px solid rgba($charcoal,.05);
    @include pvw(7,0);

    &:last-child {
      border-bottom: none;
    }
  }

  th, td {
    display: block;
    width: 100%;
    text-align: center;
    padding: 0;
  }

  th {
    @include flex(ce,fs);
  }

  .require {
    background: $accent;
    @include flex(ce,ce);
    color: $truewhite;
    font-weight: bold;
    @include fsvw(10);
    transform: scale(.9);
    padding: 0 1em;
    border-radius: .4em;
    margin-right: .4em;
  }

  input[type='text'],
  input[type='email'],
  input[type='tel'] {
    display: block;
    width: 126%;
    border: 1px solid rgba($charcoal,.1);
    padding: .7em .7em;
    @include fsvw(16);
    transform: scale(.8);
    border-radius: .3em;
    margin-left: -13%;
  }

  textarea {
    display: block;
    width: 126%;
    height: 8em;
    border: 1px solid rgba($charcoal,.1);
    padding: .7em .7em;
    @include fsvw(16);
    transform: scale(.8);
    border-radius: .3em;
    margin-left: -13%;
  }

  button,
  input[type="button"],
  input[type="submit"] {
    @include mtvw(10);
    @include fsvw(16);
    border: none;
    display: block;
    width: 100%;
    height: 2.2em;
    font-weight: bold;
    background: #00bb00;
    color: $truewhite;
    background-image: url('../img/common/send.png');
    @include bgsvw(30,30);
    background-repeat: no-repeat;
    background-position: right 3em center;
  }
}

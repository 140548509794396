/*
---
# このファイルは './_development/_source/_fonts/_cft/_lib/_cft.scss' にて編集してください。
name: CFT
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
  - draft
#  - latest
#  - deprecated
category:
  - Structure/Object/Utility
---
#### Basic Use

`cft-fonts` が有効な文字列をテキストで記述し、`cft` クラスを持つ親要素で wrap することで利用できます。フォントであるため、`font-size` や `color` などの CSS プロパティも利用できます。

```html
<div class="cft" style="font-size: 3em;">EARCH-YOU</div>
```

または、CFT のフォントファミリー`cft-fonts`を指定することで対象の文字列を `cft-fonts` にてレダリングする方法もあります。
この場合、`-moz-osx-font-smoothing: grayscale;` および `-webkit-font-smoothing: antialiased;` を指定することで滑らかなアンチエイリアスを掛けることができます。
※ `cft` クラスはそれらのスタイルも包含しています。

```html
<!-- フォントのレンダリング調整なし -->
<div style="font-family: 'cft-fonts';">
  DEV書房
</div>

<!-- フォントのレンダリング調整あり -->
<div style="font-family: 'cft-fonts'; -moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased;">
  DEV書房
</div>
```
*/

@font-face {
  font-family: "cft-fonts";
  src: url('../fonts/cft/cft-fonts.eot');
  src: url('../fonts/cft/cft-fonts.eot?#iefix') format('eot'),
    url('../fonts/cft/cft-fonts.woff2') format('woff2'),
    url('../fonts/cft/cft-fonts.woff') format('woff'),
    url('../fonts/cft/cft-fonts.ttf') format('truetype'),
    url('../fonts/cft/cft-fonts.svg#cft-fonts') format('svg');
  font-weight: normal;
  font-style: normal;
}

.cft {
  font-family: "cft-fonts";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: auto;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
}

// ----------------------------------------
// Icon Sizes
// ----------------------------------------

// makes the font 33% larger relative to the icon container

.cft-lg {
  font-size: (4em / 3);
  line-height: (3em / 4);
  vertical-align: -.0667em;
}

.cft-xs {
  font-size: .75em;
}

.cft-sm {
  font-size: .875em;
}

@for $i from 1 through 10 {
  .cft-#{$i}x {
    font-size: $i * 1em;
  }
}
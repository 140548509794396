.p-incite {
  @include gradbg();
  position: relative;

  &::before,
  &::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 55vw 6vw 0;
    border-color: transparent;
    border-bottom-color: $truewhite;
    position: absolute;
    bottom: -1px;

    @include gtSM {
      border-width: 0 352px 38px 0;
    };

    @include ltXXXS {
      border-width: 0 176px 19px 0;
    };
  }

  &::before {
    left: 0;
  }
  &::after {
    right: 0;
    transform: rotateY(180deg);
  }

  &__shape {
    display: block;
  }

  &__text {
    display: block;
    text-align: center;
    @include fsvw(15);
    color: $accent;
    @include pvw(25,5,40);
    line-height: 1;
    @include gradbg(rgba($truewhite,1),rgba($truewhite,.6),bottom);
    font-weight: 500;

    span {
      @include NotoSerif;
      @include fsvw(21);
      letter-spacing: .01em;
    }

    em {
      @include NotoSerif;
      @include fsvw(30);
      letter-spacing: 0;
    }
  }
}

/*
---
name: Heading
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Utility
---

*/

.u-heading {

  // ------------------------------
  // Default styling
  // ------------------------------

  $fs: 60;
  $rate: 0.1;
  font-weight: 300;
  margin-top: 3rem;
  margin-bottom: 3rem;

  &--xl {
    @extend .u-heading ;
    @include fs( $fs * ( 1 - 0 * $rate ) );
  }

  &--lg {
    @extend .u-heading ;
    @include fs( $fs * ( 1 - 1 * $rate ) );
  }

  &--md {
    @extend .u-heading ;
    @include fs( $fs * ( 1 - 2 * $rate ) );
  }

  &--sm {
    @extend .u-heading ;
    @include fs( $fs * ( 1 - 3 * $rate ) );
  }

  &--xs {
    @extend .u-heading ;
    @include fs( $fs * ( 1 - 4 * $rate ) );
  }

  &--xxs {
    @extend .u-heading ;
    @include fs( $fs * ( 1 - 5 * $rate ) );
  }

  // ------------------------------
  // Additional styling
  // ------------------------------

  &--borderd {
    border-bottom: 1px solid rgba($grey,.1);
    padding-bottom: 1rem;
  }
}

.p-index-insta {
  @include flex(fs,fs);

  a,
  &__comming {
    display: block;
    width: calc(100vw / 3);
    height: calc(100vw / 3);

    @include gtSM {
      width: calc(640px / 3);
      height: calc(640px / 3);
    };

    @include ltXXXS {
      width: calc(320px / 3);
      height: calc(320px / 3);
    };

    img {
      width: 100%;
      height: 100%;
      @include object-fit;
    }
  }
}

.l-footer-navigation {
  background: lighten($white,2%);

  &__body {
    @include flex(fs,fs);
    position: relative;
    // border-bottom: 1px solid $lightsilver;

    &::after {
      content: '';
      display: block;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: $lightsilver;
      position: absolute;
    }
  }

  &__item {
    width: 50%;
    border-bottom: 1px solid $lightsilver;

    &:nth-child(2n-1) {
      border-right: 1px solid $lightsilver;
    }
  }

  &__link {
    color: $charcoal;
    @include fsvw(10);
    font-weight: 400;
    display: block;
    @include pvw(10,10);
    @include flex(ce,sb,false);
    @include glyph-after('\F01B') {
      transform: scale(.8);
    };
  }

  &__link-text {
    display: block;
    @include ellipsis;
    transform: scale(.95);
    transform-origin: left center;
  }
}

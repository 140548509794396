.c-content {
  padding-top: 4em;
  padding-bottom: 4em;

  @include ltMD {
    padding-top: 3em;
    padding-bottom: 3em;
  };

  @include ltSM {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
  };

  @include ltXS {
    padding-top: 2em;
    padding-bottom: 2em;
  };
}

.p-ribbon-label {
  @include wvw(296);
  @include hvw(25);
  @include mvw(10,auto);
  background: url('../img/common/bg-ribbon-label.png');
  background-size: 296px 25px;
  @include bgsvw(296,25);
  background-position: center center;
  text-align: center;
  @include NotoSerif;
  color: $truewhite;
  @include fsvw(14);
}

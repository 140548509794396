.p-hairset-movie {
  @include mvw(15,0);
  @include pvw(15,30);

  .wp-video {
    background: $charcoal;

    video {
      display: block;
    }
  }
}

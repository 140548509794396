.c-hero-image {
  height: 100%;

  @include gtMD {
    position: fixed;
    width: 100%;
    height: 100%;
    // z-index: -1;
  };

  &__item {
    width: 100%;
    height: 100%;
    display: block;
    @include object-fit;
  }
}

// ----------------------------------------
//  Placement of images posted in WordPress
// ----------------------------------------

img {
  height: auto;

  &.aligncenter {
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
  }

  &.centered {
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
  }

  &.alignright {
    max-width: calc( 50% - 1em );
    // padding: 4px;
    margin: 0 0 1em 1em;
    display: inline;
  }

  &.alignleft {
    max-width: calc( 50% - 1em );
    // padding: 4px;
    margin: 0 1em 1em 0;
    display: inline;
  }
}

.alignright {
  float: right;
}

.alignleft {
  float: left;
}

.p-place-archive {
  color: $charcoal;

  &__introduce {
    @include pvw(15);
    @include fsvw(11);
  }

  &__body {
    @include flex(st,fs);
  }

  &__item {
    width: calc(50% - 1px);
    margin-bottom: 2px;
    font-weight: 400;
    background: url('../img/common/ptn-2.png');
    background-size: 60px 60px;
    color: $charcoal;

    &:nth-child(2n) {
      margin-left: 2px;
    }
  }

  &__thumb {
    @include hvw(100);
    overflow: hidden;

    & > img {
      width: 100%;
      height: 100%;
      @include object-fit;
    }
  }

  &__detail {
    @include pvw(10);
  }

  &__name {
    @include fsvw(12);
    font-weight: bold;
    color: $accent;
  }

  &__category {
    @include fsvw(10);
  }

  &__info {
    @include fsvw(10);
  }
}

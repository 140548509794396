/*
---
name: Screen Reader Text
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Utility
---

音声読み上げツールやソフトへの対応のため、アイコンやシェイプなどを使用したボタン、リンクの子要素に対して `.u-screen-reader-text`  クラスを付与した操作内容を指定してください。

```html
<button class="c-nav-menu u-bg-transparent">
  <i class="fas fa-caret-square-down"></i>
  <span class="u-screen-reader-text">メニューを開閉する</span>
</button>
```
```jade
//- Pug
button.c-nav-menu.u-bg-transparent
  i.fas.fa-caret-square-down
  span.u-screen-reader-text メニューを開閉する
```

*/

.u-screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}

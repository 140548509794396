.p-stripe-hr {
  $this: &;
  @include gradbg;
  @include fsvw(18);
  @include NotoSerif;
  color: $charcoal;
  @include mvw(20,-18,0);
  width: 120%;
  transform: rotate(3deg);

  &--reverse {
    transform: rotate(-3deg);
  }

  &__item {
    @include flex(ce,ce);
    // height: 100%;
    @include hvw(30);
    @include pvw(10);
    background-color: rgba($truewhite,.3);
    background-image: repeating-linear-gradient(
      -45deg,
      transparent 0,
      transparent 4px,
      rgba($truewhite,1) 4px,
      rgba($truewhite,1) 8px
    );
    position: relative;
    z-index: 2;
  }
}

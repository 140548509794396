.u {
  &-NotoSans {
    @include NotoSans;
  }
  &-NotoSerif {
    @include NotoSerif;
  }
  &-Roboto {
    @include Roboto;
  }
  &-RobotoSlab {
    @include RobotoSlab;
  }
  &-EB {
    @include EB;
  }
}

.p-entryflow {

  &--first {
    @include mtvw(-20);
  }

  &--beginners {
    @include pvw(20,0,40);
  }

  &__beginners-title {
    @include mbvw(10);
  }

  &__before-after {
    @include wvw(320);
    @include mvw(20,auto);
  }

  &__row {
    @include pvw(5,0,5,5);
  }
}

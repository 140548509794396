.c-siteid {
  @include flex(ce,fs);

  &__logo {
    display: block;
    @include wvw(122);
    @include hvw(32);
    // width: 122px;
    // height: 32px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__name {
    display: block;
    @include NotoSerif;
    // font-size: 12px;
    @include fsvw(12);
    @include mlvw(10);
    // margin-left: 10px;
  }
}

.p-article-calendar {
  color: $charcoal;
  @include fsvw(12);
  line-height: 2;
  font-weight: 500;
  border-bottom: 1px solid rgba($charcoal,.1);

  &__inner {
    position: relative;
    @include pvw(30,40);
  }

  a {
    color: $accent;
    display: inline-block;
    position: relative;

    &::after {
      content: '';
      display: inline-block;
      width: calc(100% - .2em);
      height: 1px;
      background: $accent;
      position: absolute;
      bottom: 0;
      left: .1em;
    }
  }

  &__title {
    text-transform: uppercase;
    position: absolute;
    letter-spacing: .1em;
    @include Roboto;
    font-weight: bold;
    color: $black;
  }

  table {
    table-layout: fixed;
    width: 100%;
  }

  caption {
    text-align: right;
    padding: 0 1em 1.2em;
  }

  th,td {
    text-align: center;
  }

  thead {}

  tfoot {
    td {
      padding-top: 1.2em;
    }
    td#prev {
      text-align: left;
    }
    td#next {

      text-align: right;
    }
  }

  tbody {}

  tr {}

  th {}

  td {}
}

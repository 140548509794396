.p-feature-happypoint {
  @include flex(fs,fs);
  @include pvw(5);
  @include mtvw(-50);

  &__item {
    width: 49%;
    text-align: center;
    @include mbvw(10);

    &:nth-child(2n) {
      margin-left: 2%;
    }
  }

  &__image {
    @include wvw(108);
    display: block;
    margin: auto;
  }

  &__label {
    @include fsvw(10);
    display: inline-block;
    background: #e65a97;
    color: $truewhite;
    font-weight: bold;
    @include RobotoSlab;
    transform: scale(.9);
    border-radius: 1em;
    font-weight: bold;
    letter-spacing: 0;
    padding: 0 1em;
  }

  &__lead {
    text-align: left;
    @include fsvw(10);
    letter-spacing: 0;
    line-height: 1.6;
    @include pvw(5,15);

    &--kisekinotebasaki {
      font-weight: bold;
      text-align: center;
    }

    em {
      color: #ff4672;
      font-weight: bold;
      font-size: 1.1em;
    }

    small {
      display: block;
      color: #ff4672;
    }
  }

  &__kisekinotebasaki {
    width: 100%;
    background: #fffaeb;
    @include fsvw(10);
    border-radius: .4em;
    @include pvw(15,20);
    position: relative;
    @include flex(ce,sb);
    @include mtvw(50);
  }

  &__kisekinotebasaki-label {
    position: absolute;
    @include tvw(-10);
    left: calc(50% - 4em);
  }

  &__kisekinotebasaki-baroon {
    @include wvw(90);
    position: absolute;
    @include tvw(-40);
  }

  &__kisekinotebasaki-logo {
    @include wvw(150);
    margin: auto;
  }

  &__kisekinotebasaki-detail {
    width: 60%;
    text-align: center;
  }

  &__kisekinotebasaki-image {
    width: 38%;
  }
}

.p-stripe-title {
  $this: &;
  @include gradbg;
  @include fsvw(18);
  @include NotoSerif;
  color: $charcoal;
  @include mvw(50,0,10);
  position: relative;

  @include index {
    @include NotoSans;
    letter-spacing: .01em;
    font-weight: 500;
  };

  @include lower {
    letter-spacing: .02em;
    @include mvw(44,0,20);

    &--entryflow {
      @include mvw(40,0,15);
    }

    &--workflow {
      @include mvw(35,0,10);
    }
  };


  &__item {
    @include flex(ce,ce);
    width: 100%;
    height: 100%;
    @include pvw(5);
    background-color: rgba($truewhite,.3);
    background-image: repeating-linear-gradient(
      -45deg,
      transparent 0,
      transparent 4px,
      rgba($truewhite,1) 4px,
      rgba($truewhite,1) 8px
    );
    position: relative;
    z-index: 2;

    &::before,
    &::after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      // border-width: 0 100vw 17px 0;
      border-width: 0 100vw 4.6vw 0;
      border-color: transparent;
      position: absolute;
      left: 0;
      z-index: -1;

      @include gtSM {
        border-width: 0 640px 28px 0;
      };
    }

    &::before {
      border-right-color: $truewhite;
      top: 0;
    }
    &::after {
      border-bottom-color: $truewhite;
      bottom: 0;
    }
  }

  &--entryflow,
  &--workflow {
    #{$this}__item {
      @include pvw(3.5);
      font-size: 110%;
      &::before,
      &::after {
        border-width: 0 100vw 4vw 0;

        @include gtSM {
          border-width: 0 640px 28px 0;
        }
      }
    }
  }

  &__inclinate {
    transform: rotate(-3deg) translateY(-.5em);
    line-height: 0;

    @include index {
      transform: rotate(-4.8deg) translateY(-.65em) scale(1.1);
    };

    @include lower {
      transform: rotate(-4.8deg) translateY(-.8em) scale(1.1);
    };

    ruby {
      font-size: 130%;

      // background: rgba(Lime,.3);
      // border: 1px solid Lime;

      rt {
        font-size: 50%;
        transform: scale(.8) translateY(.8em);

        @include index {
          visibility: hidden;
        };

        // background: rgba(Lime,.3);
        // border: 1px solid Lime;
        //
        // width: 128%;
        // transform: scale(.8) translateY(.5em) translateX(-13%);
      }
    }
  }

  &__handwrite {
    position: absolute;
    @include tvw(-38);
    @include lvw(15);
    @include wvw(120);
    z-index: 2;

    @include lower {
      @include tvw(-50);
    };
  }
}

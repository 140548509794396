.p-company-staff {
  border-top: 3px solid $black;
  border-bottom: 3px solid $black;
  @include pvw(10);
  background: url('../img/common/ptn-2.png');
  background-size: 60px 60px;

  &__headline {
    width: 55%;
    display: block;
    @include mvw(10,auto);
  }

  &__label {
    @include hvw(30);
  }

  &__label-inner {
    @include fsvw(13);
    @include hvw(30);
    @include fsvw(13);
    @include NotoSerif;
    font-weight: bold;

    &::before,
    &::after {
      @include bwvw(15);
    }

    &::before {
      @include lvw(-15);
      border-left-color: transparent;
    }

    &::after {
      @include rvw(-15);
      border-right-color: transparent;
    }
  }
}

.p-form {
  color: $charcoal;

  &__title {
    @include fsvw(18);
    @include NotoSerif;
    @include pvw(20,15,10);
  }

  &__lead {
    @include fsvw(11);
    @include pvw(0,15);
  }

  &__image {
    @include wvw(300);
    @include mvw(0,auto);
  }

  &__table-wrap {
    @include pvw(15);
    background: lighten($white,3%);
  }
}


// .p-form {
//   form {
//     position: relative;
//   }
//   div.wpcf7-validation-errors,
//   div.wpcf7-acceptance-missing {
//     background: $accentLt;
//     color: $truewhite;
//     font-weight: bold;
//     border-color: $accent;
//   }
//
//   div.wpcf7-response-output {
//     @include fsvw(10);
//     margin: 0;
//     width: 100%;
//     font-weight: bold;
//     position: absolute;
//     @include bvw(-5);
//     left: 0;
//     letter-spacing: 0;
//   }
//
//   span.wpcf7-not-valid-tip {
//     color: $accent;
//     font-weight: bold;
//   }
//
//   .wpcf7-form {
//     max-height: 1400px;
//     transition: max-height .3s;
//     overflow: hidden;
//
//     &.sent {
//       @include mxhvw(25);
//
//       .p-contact-table {
//         transition: all .3s;
//         transition-delay: .7s;
//         @include opacity(0);
//         display: none;
//       }
//       div.wpcf7-response-output {
//         position: static;
//         bottom: 0;
//       }
//     }
//   }
// }

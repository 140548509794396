.p-ribbon-black {
  $this: &;
  @include hvw(20);
  overflow: hidden;

  &__inner {
    background: $trueblack;
    color: $truewhite;
    @include fsvw(10);
    @include wvw(260);
    margin: 0 auto;
    padding: 0 1em;
    position: relative;
    @include hvw(20);
    text-align: center;
    font-weight: bold;
    @include flex(ce,ce);

    &--article-meta {
      @include wvw(130);
    }

    &::before,
    &::after {
      position: absolute;
      content: '';
      display: block;
      width: 0;
      height: 0;
      z-index: 3;
      @include bwvw(10);
      border-style: solid;
      border-color: $trueblack;
      top: 0;
      transform: scale(1.1);
    }

    &::before {
      @include lvw(-10);
      border-left-color: transparent;
    }

    &::after {
      @include rvw(-10);
      border-right-color: transparent;
    }
  }
}

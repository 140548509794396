.p-for-job-seeker {
  @include pvw(10,10,30);
  @include gradbg(rgba($cs04,.1),rgba($cs01,.1),left);
  position: relative;
  color: $charcoal;

  &::before,
  &::after {
    @include fsvw(20);
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: auto;
    right: 0;
    width: 0;
    height: 0;
    border-top: 1em solid transparent;
    border-right: 50vw solid $truewhite;
  }

  &::after {
    right: auto;
    left: 0;
    border-right: none;
    border-top: 1em solid transparent;
    border-left: 50vw solid $truewhite;
  }

  &__badge {
    @include wvw(150);
    @include mvw(10,auto);
  }

  &__closing {
    @include fsvw(20);
    @include NotoSerif;
    text-align: center;
    margin-bottom: 1em;

    &--l {
      margin-bottom: 2em;
      margin-top: 1em;
    }
  }
}

.p-feature-salary-example {
  @include flex(fs,fs);
  @include pvw(5);

  &__item {
    @include fsvw(10);
    width: 49%;
    background: #fffaeb;
    border-radius: .4em;
    @include pvw(5);
    @include flex(st,fs);
    @include mbvw(5);
    box-shadow: 0 .2em .4em rgba($trueblack,.07);

    &:nth-child(2n) {
      margin-left: 2%;
    }
  }

  &__image {
    @include wvw(60);
    @include hvw(60);
  }

  &__detail {
    @include wvw(90);
    letter-spacing: 0;
    line-height: 1.4;
    transform: scale(.9);
    margin-right: -20%;

    b {
      font-weight: bold;
      color: $accent;
    }

    li {
      &::before {
        content: '\2605';
        color: $accent;
      }
    }
  }

  &__income {
    background: #e65a97;
    color: $truewhite;
    @include fsvw(12);
    @include NotoSerif;
    text-align: center;
    border-radius: .2em;
    width: 100%;
    line-height: 1.1;
    @include mtvw(5);
    font-weight: bold;
    padding-bottom: .5em;
    letter-spacing: .01em;

    em {
      display: inline-block;
      font-size: 1.6em;
      position: relative;
      bottom: -.1em;
      padding: 0 .1em;
    }
  }
}

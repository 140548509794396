.p-beginners-message {
  color: $charcoal;

  &__title {
    text-align: center;
    @include fsvw(18);
    @include NotoSerif;
  }

  &__writer-profile {
    @include flex(fs,sb);
    @include pvw(10);
  }

  &__writer-lead {
    @include fsvw(12);
    font-weight: 300;
  }

  &__writer-name {
    @include fsvw(11);
    @include mtvw(10);
    @include NotoSerif;
    text-align: center;

    em {
      font-size: 120%;
      margin-left: 1.5em;
    }
  }

  &__writer-image {
    @include pvw(10);
    width: 30%;
  }

  &__writer-detail {
    width: calc(100% - 30% - 2%);
  }

  &__body {
    background: #f0efed;
    @include pvw(10);
    @include fsvw(11);
    font-weight: 300;

    &::before,
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 4px;
      background: url('../img/common/hr.png');
      background-size: 288px 4px;
      background-position: left center;
      margin: 10px 0;
    }
  }
}

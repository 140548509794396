.p-beginners-workstyle {
  padding: 10px;

  &__title {
  }

  &__item {
    @include flex(ce,sb,false);
    @include pvw(5,0);
  }

  &__icon {
    @include wvw(65);
    @include mnwvw(65);
    @include hvw(65);
    @include ptvw(14);
    @include pbvw(12);
    margin-right: 4%;
    background: $truewhite;
    border-radius: 50%;
    box-shadow: 0 4px 4px rgba($trueblack,.1) inset;
    @include flex(ce,ce);
    @include Roboto;
    @include fsvw(12);
    font-weight: bold;
    text-align: center;
    color: $accent;
    line-height: 0;

    em {
      display: block;
      width: 100%;
      font-size: 130%;
      font-weight: 800;
      line-height: 0;
    }
  }

  &__lead {
    @include fsvw(11);
    width: 100%;
    // width: calc(100% - 65px - 15px);

    small {
      color: $accent;
      display: block;
      font-size: 80%;
    }
  }
}

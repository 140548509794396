.u-fluid-button {
  @include flex(ce,ce,false);
  background-color: $cs02Lt;

  button {
    width: 100%;
    height: 40px;
    line-height: 40px;
    display: block;
    font-family: inherit;
    background: transparent;
    border: none;
    color: $truewhite;
    font-size: 14px;
    font-weight: 500;
    padding: 0 20px;
    border-radius: 0;

    &:hover {
      background: rgba($truewhite,.2);
    };

    &:not(:first-child) {
      border-left: 1px solid rgba($truewhite,.5);
    }
  }
}

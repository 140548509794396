.c-nav-trigger-alt {
  display: block;
  position: absolute;
  @include tvw(62);
  @include rvw(5);
  @include Roboto;
  font-size: 10px;
  @include wvw(52);
  letter-spacing: .38em;
  text-align: center;
  transform: scale(.7);
  z-index: 10;
  // background: rgba(lime,.4);
  margin-top: -1vw;
}

.c-nav-trigger {
  font-size: inherit;
  position: absolute;
  @include tvw(20);
  @include rvw(5);
  @include wvw(52);
  @include hvw(52);
  border: none;
  background: transparent;
  z-index: 20;
  // background: rgba(#f00,.4);

  // Default Bar Styling
  // --------------------
  $btnW: 1.8em;
  $btnH: .8em;
  $bdrW: 2px;
  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    width: $btnW;
    top: 50%;
    left: 50%;
    margin-left: #{-.5*$btnW};
    @include ease;
  }

  & {
    // Double line style
    // --------------------

    &::before,
    &::after {
      width: $btnW;
      height: $bdrW;
      background: currentColor;
    }

    &::before {
      margin-top: calc( #{-.3*$btnH} - #{1.5*$bdrW} );
    }

    &::after {
      margin-top: calc( #{.3*$btnH} + #{.5*$bdrW} );
    }

    &.is-active {
      transform: rotate(180deg);

      &::before,
      &::after {
        margin-top: #{-.5*$bdrW};
      }

      &::before {
        transform: rotate(25deg);
      }

      &::after {
        transform: rotate(-25deg);
      }
    }
  }

  &--triple,
  &--last-short {
    // Triple line style
    // --------------------

    &::before {
      height: $btnH*.5;
      margin-top: calc( #{-.5*$btnH} - #{1.5*$bdrW} );
      border-top: $bdrW solid;
      border-bottom: $bdrW solid;
      background: transparent;
    }

    &::after {
      width: $btnW;
      height: $bdrW;
      margin-top: calc( #{.5*$btnH} + #{.5*$bdrW} );
      background: currentColor;
    }

    &.is-active {
      transform: rotate(180deg);

      &::before,
      &::after {
        border-width: 0;
        width: $btnW;
        height: $bdrW;
        background: currentColor;
        margin-top: #{-.5*$bdrW};
      }

      &::before {
        transform: rotate(30deg);
      }

      &::after {
        transform: rotate(-30deg);
        width: $btnW;
      }
    }
  }

  &--last-short {
    // last line short style
    // --------------------

    &::after {
      width: #{.5*$btnW};
    }
  }
}

.c-pagetop-button {
  display: block;
  position: relative;
  color: $charcoal;
  @include wvw(100);
  @include hvw(66);
  @include mvw(20,auto);

  &::before,
  &::after {
    content: '';
    display: block;
    @include wvw(3);
    @include hvw(40);
    background: currentColor;
    position: absolute;
    top: 0;
    left: 50%;
  }

  &::before {
    transform: rotate(45deg);
    @include mlvw(-14);
  }

  &::after {
    transform: rotate(-45deg);
    @include mlvw(14);
    @include tvw(8);
    @include hvw(30);
  }

  &__text {
    @include Roboto;
    @include fsvw(12.5);
    font-weight: bold;
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    line-height: 1;
    letter-spacing: .25em;
    text-indent: .5em;
  }
}

.p-search {

  &__result {
    @include fsvw(10);
    padding: 1em 2em;
    color: $charcoal;
    background: $white;

    &--notfound {
      padding: 2em 2em;
    }
  }
}

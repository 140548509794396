.p-article-social {
  @include flex(ce,ce);

  .addtoany_list {
    @include flex(ce,ce);
  }

  a {
    display: block !important;
    @include mvw(0,5,20);
    padding: 0 !important;

    &, & > span {
      @include mxwvw(30);
      @include mnwvw(30);
      @include mxhvw(30);
      @include mnhvw(30);
    }
  }
}

.p-jobset {
  @include pvw(0,10);
  background: url('../img/for-job-seeker/bg.png');
  background-size: auto 100%;
  background-position: right center;
  background-repeat: no-repeat;
  background-color: $truewhite;
  @include mbvw(30);

  &__inner {}

  &__title {
    @include NotoSerif;
    @include fsvw(17);
    @include mvw(10);
    line-height: 1.6;
  }

  &__image {
    @include mvw(10,10);
    @include wvw(180);
  }

  &__list {
    width: 67.5%;
    @include fsvw(12);
    line-height: 2;
    color: $charcoal;
    @include mvw(0,10);
    @include flex(fs,fs);
  }

  &__list-item {
    display: inline-block;
    border: 1px solid #f5a9d0;
    background: #fff6fb;
    padding: 0 1em;
    @include mvw(5,5,0,0);
  }
}

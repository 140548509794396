.p-pursuit {
  @include pvw(10);
  color: $charcoal;

  &__title {
    text-align: center;
    @include NotoSerif;
    color: $accent;
    @include fsvw(21);
    @include mvw(10);
    letter-spacing: 0;
  }

  &__image {
    @include mvw(10,0);
  }

  &__text {
    @include fsvw(13);
    @include mvw(10,0);
  }

  &__subtitle {
    @include NotoSerif;
    color: $accent;
    @include fsvw(18);
    letter-spacing: 0;
    margin-top: 1em;
  }

  &__text {}
}

// --------------------------------------------------
// Basic styling
// --------------------------------------------------

html {
  $baseFontSize: 62.5%;
  font-size: $baseFontSize;
  @include ltXS {
    height: 100%;
  };
  @include gradbg(rgba($cs01,.1),rgba($cs04,.1));

  // @include ltMD {
  //   font-size: $baseFontSize * 0.84;
  // };
  //
  // @include ltSM {
  //   font-size: $baseFontSize * 0.80;
  // };
  //
  // @include ltXS {
  //   font-size: $baseFontSize * 0.75;
  // };
  // @include ltXXS {
  //   font-size: $baseFontSize * 0.9;
  // };

}

body {
  @include NotoSans;
  @include fs(16);
  line-height: 1.8;
  min-width: 320px;
  min-height: 100vh;
  max-width: 640px;
  margin: 0 auto !important;
  position: relative;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: auto;
  background-color: $truewhite;
  color: $main;
  height: 100%;
  box-shadow: 0 0 10px rgba($main,.15);
  z-index: 0;

  &.is-entire-nav-target {
    height: 100vh;
    overflow: hidden;
  }

  &.is-invalid-scroll {
    height: 100vh;
    overflow: hidden;
  }
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

// --------------------------------------------------
// Flow content
// --------------------------------------------------

// --------------------------------------------------
// Phrasing content
// --------------------------------------------------

img {
  max-width: 100%;
  display: block;
}

a {
  text-decoration: none;
  font-weight: bold;
  word-break: break-all;
  @include linear;

  @include hover {
    @include opacity(.8);
  };
}

b,
strong {
  font-weight: bold;
}

small {
  font-size: 80%;
}

// --------------------------------------------------
// Form Element
// --------------------------------------------------

button,
input[type="button"],
input[type="submit"] {
  color: currentColor;
  border: 1px solid;
  cursor: pointer;
  @include ease;
  @include fs(16);
  text-align: center;
}

input[type="button"],
input[type="submit"] {
  -webkit-appearance: button;
  -moz-appearance: button;
}

input:focus,
textarea:focus,
keygen:focus,
select:focus { outline-offset: -2px; }
:focus { outline: none; }
a:focus { outline: none;text-decoration: none; }

main {

  // --------------------------------------------------
  // Only in Main Content
  // --------------------------------------------------

  // a {
  //   color: rgba($truewhite,.6);
  //
  //   @include hover {
  //     color: $truewhite;
  //   };
  // }
}

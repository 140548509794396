.c-entire-overlay {
  display: none;
  position: fixed;
  z-index: 1010;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 640px;
  height: 100%;
  background: rgba($cs03,.8);

  @include gtSM {
    left: calc(50% - 320px);
  };
}

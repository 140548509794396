.p-entryflow-bringing {
  @include mvw(5);
  @include fsvw(12);
  @include pvw(20,5,5);
  border: 3px solid $accent;
  border-radius: .4em;
  position: relative;

  &__title {
    position: absolute;
    @include tvw(-23);
    font-family: $NotoSans !important;
    left: calc(50% - 148vw*.2875);

    @include gtSM {
      left: calc(50% - 272px);
    };

    @include ltXXXS {
      left: calc(50% - 148px);
    };
  }

  &__header {
    @include fsvw(13);
    @include pvw(0,10);
    @include flex(ce,fs);
  }

  &__icon {
    width: 1.4em;
    height: 1.4em;
    background: $accent;
    color: $truewhite;
    @include flex(ce,ce);
    @include mrvw(5);
    border-radius: 50%;
    @include Roboto;
    line-height: 0;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0;

    &--attention {
      color: inherit;
      background: #ffde47;
    }
  }

  &__label {
    @include fsvw(13);

    &--anotation {
      color: Red;
    }
  }

  &__detail {
    @include pbvw(10);
  }

  &__list {
    @include flex(st,sa,false);
  }

  &__item {
    @include flex(st,ce);
    text-align: center;
    @include pvw(5);
    max-width: 25%;
  }

  &__item-image {
    @include hvw(80);
    @include flex(ce,ce);

    & > img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  &__item-name {
    @include fsvw(11);
    display: block;
    width: 100%;
  }

  &__lead {
    @include fsvw(11);
    @include plvw(20);
  }
}

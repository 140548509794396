.p-company-privacy {
  color: $charcoal;
  @include pvw(10,10);

  &__title {
    @include fsvw(21);
    @include NotoSerif;
    text-align: center;
    line-height: 1.4;
    @include pvw(10,0);
  }

  &__title-small {
    display: block;
    @include fsvw(12);
    @include Roboto;
    transform: scale(.7);
  }

  &__lavel {
    @include NotoSerif;
    @include fsvw(14);
    @include mvw(10,0);
  }

  &__text {
    @include fsvw(9);
    @include mbvw(20);
  }
}

.l-footer {
  background: $truewhite;
  @include pbvw(60);
  position: relative;
  z-index: 900;
  @include gtSM {
    overflow: hidden;
  };

  &__banner-area {
    @include pvw(15);

    & > a,
    & > img {
      display: block;
      @include mbvw(10);
    }
  }

  &__permission-number {
    text-align: center;
    @include fsvw(10);
    color: $charcoal;
    @include pvw(11);
  }

  &__closing {}

  &__closing-image {
  }

  &__closing-lead {
    @include fsvw(15);
    @include NotoSerif;
    background: $trueblack;
    color: $truewhite;
    text-align: center;
    @include pvw(10);
    letter-spacing: 0;
    line-height: 1.4;
  }

  &__attention {
    text-align: center;
    @include fsvw(10);
    border-top: 1px solid rgba($charcoal,.1);
    @include pvw(10,0);
    color: $charcoal;
    letter-spacing: 0;
  }

  &__attention-inner {
    transform: scale(.8);
    margin-left: -4%;
    margin-right: -4%;
  }

  &__social {
    text-align: center;
    @include fsvw(10);
    border-top: 1px solid rgba($charcoal,.1);
    @include pvw(10,0);
    color: $charcoal;
  }

  &__social-inner {

    .addtoany_list {
      @include flex(ce,ce);
    }

    a {
      display: block !important;
      @include mvw(0,5);
      padding: 0 !important;

      &, & > span {
        @include mxwvw(30);
        @include mnwvw(30);
        @include mxhvw(30);
        @include mnhvw(30);
      }
    }
  }

  &__legal {
    text-align: center;
    @include fsvw(10);
    @include NotoSerif;
    border-top: 1px solid rgba($charcoal,.1);
    @include pvw(10,0);
    color: $charcoal;
  }

  &__legal-inner {
    transform: scale(.8);
    margin-left: -4%;
    margin-right: -4%;
  }

  &__legal-permission-number {}

  &__legal-copyright {
    letter-spacing: 0;
  }

}

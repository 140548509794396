.p-strategy {
  position: relative;
  color: $charcoal;

  &::before,
  &::after {
    content: '';
    display: block;
    left: 0;
    right: 0;
    bottom: 0;
    @include hvw(130);
    position: absolute;
    //
    // background: #ccc;
  }

  &::before {
    @include gradbg;
    // background: #000;
    // z-index: 10;
  }

  &::after {
    @include gradbg($truewhite,rgba($truewhite,.7),bottom);
  }

  &__inner {
    position: relative;
    z-index: 1;
    @include pvw(10);

    &::before,
    &::after {
      @include fsvw(20);
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: auto;
      right: 0;
      width: 0;
      height: 0;
      border-top: 1em solid transparent;
      border-right: 50vw solid $truewhite;
    }

    &::after {
      right: auto;
      left: 0;
      border-right: none;
      border-top: 1em solid transparent;
      border-left: 50vw solid $truewhite;
    }
  }

  &__title {
    @include wvw(150);
    @include mvw(10,auto);
  }

  &__lead {
    text-align: center;
    @include NotoSerif;
    @include fsvw(16);
  }

  &__chart{
    @include mvw(20,auto);
  }

  &__text {
    text-align: center;
    @include fsvw(15);
  }

  &__item {
    @include mvw(20,auto);
  }
}

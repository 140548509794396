.c-navbelow {
  background: $truewhite;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  text-transform: uppercase;
  text-align: center;
  @include fsvw(10);
  border-top: 1px solid rgba($charcoal,.1);
  border-bottom: 1px solid rgba($charcoal,.1);

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    @include pvw(14);
    color: $charcoal;
    font-weight: 300;
  }

  &__item {
    display: block;
    width: 100%;
    line-height: 1em;
    max-width: calc(34%);

    &--prev a {
      @include glyph-before('\F01A') {
        transform: scale(.8);
        margin-right: .4em;
      };
    }

    &--navList {
      @include flex(ce,sb);

      &::before,
      &::after {
        content: '';
        display: block;
        width: 1px;
        height: 1.2em;
        transform: rotate(20deg);
        background: rgba($charcoal,.2);
      }
    }

    &--next a {
      @include glyph-after('\F01B') {
        transform: scale(.8);
        margin-left: .4em;
      };
    }
  }
}

/*
---
name: Tab
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Component
---
Tab は、トリガー要素となる `.c-tab-trigger]` と、ターゲット要素となる `.c-tab-target` で構成されます。

主に、カスタムデータ属性 `[data-tab-trigger]` の付与による JS によるタブトグル機能の利用を想定しています。

```html
<div class="c-tab c-container">
  <ul class="c-tab-trigger">
    <li class="c-tab-trigger__item" data-tab-trigger="[&quot;01&quot;]">トリガー01</li>
    <li class="c-tab-trigger__item" data-tab-trigger="[&quot;02&quot;]">トリガー02</li>
    <li class="c-tab-trigger__item" data-tab-trigger="[&quot;03&quot;]">トリガー03</li>
  </ul>
  <div class="c-tab-target">
    <div class="c-tab-target__item" data-tab-target="[&quot;01&quot;]">ターゲット01</div>
    <div class="c-tab-target__item" data-tab-target="[&quot;02&quot;]">ターゲット02</div>
    <div class="c-tab-target__item" data-tab-target="[&quot;03&quot;]">ターゲット03</div>
  </div>
</div>
```
```jade
//- Pug
.c-tab.c-container
  ul.c-tab-trigger
    li.c-tab-trigger__item(data-tab-trigger='["01"]') トリガー01
    li.c-tab-trigger__item(data-tab-trigger='["02"]') トリガー02
    li.c-tab-trigger__item(data-tab-trigger='["03"]') トリガー03
  .c-tab-target
    .c-tab-target__item(data-tab-target='["01"]') ターゲット01
    .c-tab-target__item(data-tab-target='["02"]') ターゲット02
    .c-tab-target__item(data-tab-target='["03"]') ターゲット03
```

JS によるタブトグル機能については、 [JS-Transpile#Tab Toggle](../../../Documentation/Preprocessor/JS-Transpile/index.html#tab-toggle) のドキュメントを参照してください。

```html
<div class="c-tab c-container">
  <ul class="c-tab-trigger">
    <li class="c-tab-trigger__item" data-tab-trigger="[&quot;04&quot;,&quot;fade&quot;,&quot;group02&quot;]">トリガー04</li>
    <li class="c-tab-trigger__item is-active" data-tab-trigger="[&quot;05&quot;,&quot;fade&quot;,&quot;group02&quot;]">トリガー05</li>
    <li class="c-tab-trigger__item" data-tab-trigger="[&quot;06&quot;,&quot;fade&quot;,&quot;group02&quot;]">トリガー06</li>
  </ul>
  <div class="c-tab-target">
    <div class="c-tab-target__item" data-tab-target="[&quot;04&quot;,&quot;group02&quot;]">ターゲット04</div>
    <div class="c-tab-target__item" data-tab-target="[&quot;05&quot;,&quot;group02&quot;]">ターゲット05</div>
    <div class="c-tab-target__item" data-tab-target="[&quot;06&quot;,&quot;group02&quot;]">ターゲット06</div>
  </div>
</div>
```
```jade
//- Pug
.c-tab.c-container
  ul.c-tab-trigger
    li.c-tab-trigger__item(data-tab-trigger='["04","fade","group02"]') トリガー04
    li.c-tab-trigger__item.is-active(data-tab-trigger='["05","fade","group02"]') トリガー05
    li.c-tab-trigger__item(data-tab-trigger='["06","fade","group02"]') トリガー06
  .c-tab-target
    .c-tab-target__item(data-tab-target='["04","group02"]') ターゲット04
    .c-tab-target__item(data-tab-target='["05","group02"]') ターゲット05
    .c-tab-target__item(data-tab-target='["06","group02"]') ターゲット06
```
*/

.c-tab {
  margin-bottom: 2em;
}

.c-tab-trigger {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &__item {
    padding: .2em 1em;
    background: transparent;

    &:hover {
      background: $white;
    }

    &.is-active {
      background: $white;
    }
  }
}
.c-tab-target {

  &__item {
    padding: .2em 1em;

    &.is-visible {
      background: $white;
    }
  }
}

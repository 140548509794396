.c-row {
  $this: &;
  @include flex(fs,fs);
  @include pvw(5);

  &--post {
    @include plvw(10);
    @include prvw(10);
  }

  &__item {
    width: 100%;
    position: relative;
    @include mbvw(4);
    font-weight: 400;

    #{$this}--2 & {
      width: 48%;
      width: calc((100% / 2) - (2% / 1));
      &:not(:nth-child(2n)) {margin-right: 2%;}
    }

    #{$this}--3 & {
      width: 31.333333333%;
      width: calc((100% / 3) - (4% / 2));
      &:not(:nth-child(3n)) {margin-right: 2%;}
    }

    #{$this}--4 & {
      width: 23.5%;
      width: calc((100% / 4) - (6% / 4));
      &:not(:nth-child(4n)) {margin-right: 2%;}
    }
  }

  &__badge {
    background: $main;
    color: $truewhite;
    position: absolute;
    top: -.3em;
    left: -.3em;
    @include fsvw(10);
    @include RobotoSlab;
    width: 4em;
    height: 4em;
    border-radius: 50%;
    @include flex(ce,ce);
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
    transform-origin: top left;
    transform: scale(.7);
    letter-spacing: 0;

    &--heart {
      background: url('../img/common/heart.png');
      @include fsvw(11);
      @include wvw(52);
      @include hvw(46);
      @include bgsvw(52,46);
      border-radius: 0;
      left: auto;
      right: 0;
      transform-origin: top right;
      transform: scale(.5);
    }
  }

  &__thumb {
    width: 100%;
    // height: 70vw;
    border-radius: 4px;
    overflow: hidden;
    @include mbvw(4);

    &--round {
      border-radius: 50%;

      #{$this}--2 & {
        height: calc((100vw - 10px) / 2 - 1vw ) !important;
        @include gtSM {height: 302.39px !important;};
      }

      #{$this}--3 & {
        // height: calc((100vw - 10px) / 3 - 1vw ) !important;
        // @include gtSM {height: 201.59px !important;};
        @include wvw(92);
        @include hvw(92);
        @include mvw(0,auto,5);

        @include gtSM {
          height: 169.28px !important;
        };
      }

      #{$this}--4 & {
        height: calc((100vw - 10px) / 4 - 1vw ) !important;
        @include gtSM {height: 151.19px !important;};
      }
    }

    @include gtSM {
      height: 390px;
    };

    & > img {
      width: 100%;
      height: 100%;
      @include object-fit;
    }

    #{$this}--2 & {
      // height: 35vw;
      @include gtSM {height: 260px;};
    }

    #{$this}--3 & {
      // height: 22vw;
      @include gtSM {height: 140px;};
    }

    #{$this}--4 & {
      // height: 16vw;
      @include gtSM {height: 140px;};
    }
  }

  &__post-thumb {
    width: 100%;
    height: 100vw;
    overflow: hidden;
    @include mbvw(4);

    &--round {
      border-radius: 50%;
    }

    &--shape {
      -webkit-clip-path: polygon(013% 0, 100% 0, 100% 81%, 87% 100%, 0 100%, 0 19%);
      clip-path: polygon(013% 0, 100% 0, 100% 81%, 87% 100%, 0 100%, 0 19%);
    }

    @include gtSM {
      height: 390px;
    };

    & > img {
      width: 100%;
      height: 100%;
      @include object-fit;
    }

    #{$this}--2 & {
      height: calc((100vw - 10px) / 2 - 2vw );
      @include gtSM {height: 302.39px;};
      min-height: calc((320px - 30px) / 2 );
    }

    #{$this}--3 & {
      // height: calc((100vw - 10px) / 3 - 2vw );
      // @include gtSM {height: 201.59px;};
      // min-height: calc((320px - 30px) / 3 );
      @include wvw(98);
      @include hvw(98);
      @include mvw(0,auto,5);
    }

    #{$this}--4 & {
      height: calc((100vw - 10px) / 4 - 2vw );
      @include gtSM {height: 151.19px;};
      min-height: calc((320px - 30px) / 4 );
    }

    &--shape {
      #{$this}--2 & {
        height: calc((70vw - 10px) / 2 - 1vw );
        @include gtSM {height: 211.673px;};
        min-height: calc((220px - 30px) / 2 );
      }

      #{$this}--3 & {
        height: calc((70vw - 10px) / 3 - 1vw );
        @include gtSM {height: 141.113px;};
        min-height: calc((220px - 30px) / 3 );
      }

      #{$this}--4 & {
        height: calc((70vw - 10px) / 4 - 1vw );
        @include gtSM {height: 105.833px;};
        min-height: calc((220px - 30px) / 4 );
      }
    }
  }

  &__title {
    @include fsvw(8);
    letter-spacing: 0;
    font-weight: 600;
    color: $accent;
    line-height: 1.4;
    @include mbvw(4);
  }

  &__staff-name {
    @include wvw(90);
    @include mvw(5,auto);
  }

  &__lead {
    @include fsvw(7);
    letter-spacing: 0;
    line-height: 1.4;
    @include mbvw(4);

    &--blk {
      color: $charcoal;
    }

    b {
      text-align: center;
      display: block;
      font-weight: bold;
    }
  }

  &__post-name {
    @include fsvw(9);
    letter-spacing: 0;
    color: $charcoal;
    line-height: 1.4;
    @include mbvw(4);

    #{$this}__post-thumb--round + & {
      @include pvw(0,5);
    }
  }

  &__post-date {
    @include fsvw(9);
    letter-spacing: 0;
    color: $accent;
    line-height: 1.4;
    @include mbvw(4);
    transform: scale(.9);
    transform-origin: left center;

    #{$this}__post-thumb--round + #{$this}__post-name + & {
      @include pvw(0,5);
    }

    // #{$this}__post-thumb--round + #{$this}__post-name + & {
    //   text-align: center;
    // }
  }

}

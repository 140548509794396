.p-feature {
  $this: &;
  @include gradbg;
  position: relative;
  @include pvw(5,0,10);

  &__inner {
    background-color: rgba($truewhite,.5);
    background-image: repeating-linear-gradient(
      -45deg,
      rgba($truewhite,0) 0,
      rgba($truewhite,0) 8px,
      rgba($truewhite,.4) 8px,
      rgba($truewhite,.4) 16px
    );
    position: relative;
    z-index: 3;

    @include safari {
      // background-color: rgba($truewhite,.8);
    };
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    @include hvw(16);
    background: url('../img/common/mask-texture.png');
    background-size: 40px 53.333333334px;
    background-position: left bottom;
    background-repeat: repeat-x;
    position: absolute;
    top: -1px;
    left: 0;
    z-index: 1;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include gradbg(rgba($truewhite,0),$truewhite,bottom);
    z-index: 2;
  }

  &__title {
    @include pvw(7);
  }

  &__subtitle1 {
    @include mtvw(-10);
  }

  &__text {
    @include fsvw(11);
    @include pvw(10);

    em {
      color: $accent;
      font-weight: bold;
    }
  }

  &--1 {}

  &--2 {}

  &--3 {}
}

.p-beginners-feature {
  @include pvw(10);

  &__item {
    @include flex(fs,sb);
    @include pvw(10,0);
  }

  &__icon {
    width: 17.1875%;
  }

  &__detail {
    width: 100%-17.1875%-4%;
  }

  &__title {
    @include fsvw(19);
    @include NotoSerif;
    font-weight: bold;
    color: $accent;
    line-height: 1.4;

    & > span {
      display: inline-block;
      border-bottom: .3em solid rgba(#f6ff00,.5);
      line-height: .8;
      // margin: 10px 0;
    }
  }

  &__lead {
    @include fsvw(11);
    @include ptvw(10);
  }
}

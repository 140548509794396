.c-page-navi {
  width: 100%;
  line-height: 1.6;

  .wp-pagenavi {
    @include fsvw(14);
    @include EB;
    font-weight: bold;
    @include flex(ce,ce);
    border-bottom: 4px solid $black;
    @include pvw(15,0);

    span,a {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $white;
      font-weight: bold;
      height: 2em;
      min-width: 2em;
      padding: 0 .5em;
      margin: .2em .1em;
      text-align: center;
      @include ease;
    }

    .pages {
      display: none;
      background: $white;
      color: $charcoal;
    }

    .current {
      background: $charcoal;
      color: $white;
    }

    .first,
    .last {}

    .first,
    .last,
    .page,
    .nextpostslink,
    .previouspostslink {
      color: $charcoal;
    }

    a:hover,&:focus {
      background: $charcoal;
      color: $white;
      text-decoration: none;
    }

    .larger {}

    .smaller {}

    .extend {
      color: $grey;
    }

    .nextpostslink {}

    .previouspostslink {}
  }
}

.p-faq {
  @include gradbg(rgba($cs01,.2),rgba($cs04,.2));
  @include pvw(30,10);

  &__title {
    width: 41.5625%;
    @include mvw(5,auto,15);
  }

  &__lead {
    @include fsvw(10);
    text-align: center;
    margin-bottom: 1em;
    position: relative;
    top: -.5em;
    @include NotoSerif;
    font-weight: bold;
    transform: scale(.8);

    &--faq {
      top: -1.5em;
      margin-bottom: 0;
    }
  }

  &__inner {
    @include fsvw(10);
    background: $truewhite;
    border-radius: 0;
    @include pvw(15);
  }

  &__item {
    border-bottom: 1px dotted rgba($charcoal,.1);
  }

  &__question {
    @include fsvw(11);
    @include pvw(5,0);
    color: $charcoal;
    @include flex(fs,fs,false);

    &::before {
      @include fsvw(12);
      font-weight: bold;
      content: 'Q.';
      display: block;
      @include mrvw(5);
      color: #e65a97;
    }
  }

  &__answer {
    @include fsvw(13);
    font-weight: bold;
  }

  &__answer-item {
    @include flex(fs,fs,false);
    @include pvw(5,0);

    &::before {
      @include fsvw(15);
      font-weight: bold;
      content: 'A.';
      display: block;
      @include mrvw(5);
      color: #33b6e6;
    }
  }

  &__close {
    display: block;
    text-align: center;
    @include mvw(5,auto);
    min-width: 100px;
    border: none;
    background: transparent;
    position: relative;
    @include pvw(20,10,10);
    @include fsvw(10);
    color: $charcoal;

    & > .icon {
      $borderW: 1px !default;
      $size: 8px !default;
      width: $size;
      min-width: $size;
      height: $size;
      position: absolute;
      top: 12px;
      right: calc(50% - #{.5*$size});
      border-top: 1px solid currentColor;
      transform: rotate(-45deg) scale(1.3);

      &::before {
        content: '';
        display: block;
        width: $borderW;
        height: $size - 2*$borderW;
        background: currentColor;
        margin-top: $borderW + $borderW;
        margin-left: $size - $borderW;
      }
    }

    & > small {
      @include Roboto;
      letter-spacing: .1em;
      transform: scale(.7);
      font-weight: 500;
    }
  }
}

/*
---
name: Margin
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Utility
---
 *
ほげほげ説明文
 *
```css
.u-margin {
  color: aaa;
  display: block;
}
```

*
ほげほげな説明文
*
```css
.u-margin {
  &--hoge {
    なんか
  }
}
```
*/
.u-margin {}


// ==================================================
//  General CSS Class
// ==================================================
// General class for margin.
// --------------------------------------------------
// value : m{t,l,r,b}-1 ~ m{t,l,r,b}-450
// step : 5 ~ 45 : 5
// step : 50 ~ 450 : 50
// --------------------------------------------------
@for $i from 1 through 9 {
  .mt-#{5*$i} { margin-top: #{5*$i}px; }
  .mt-#{50*$i} { margin-top: #{50*$i}px; }

  .ml-#{5*$i} { margin-left: #{5*$i}px; }
  .ml-#{50*$i} { margin-left: #{50*$i}px; }

  .mr-#{5*$i} { margin-right: #{5*$i}px; }
  .mr-#{50*$i} { margin-right: #{50*$i}px; }

  .mb-#{5*$i} { margin-bottom: #{5*$i}px; }
  .mb-#{50*$i} { margin-bottom: #{50*$i}px; }
}

// General class for margin.( unit : em )
// --------------------------------------------------
// value : m{t,l,r,b}-0em ~ m{t,l,r,b}-50em
// step : 1
// --------------------------------------------------
@for $i from 0 through 50 {
  .mt-#{$i}em { margin-top: #{$i}em; }

  .ml-#{$i}em { margin-left: #{$i}em; }

  .mr-#{$i}em { margin-right: #{$i}em; }

  .mb-#{$i}em { margin-bottom: #{$i}em; }
}

// General class for padding.
// --------------------------------------------------
// value : p{t,l,r,b}-1 ~ p{t,l,r,b}-450
// step : 5 ~ 45 : 5
// step : 50 ~ 450 : 50
// --------------------------------------------------
@for $i from 1 through 9 {
  .pt-#{5*$i} { padding-top: #{5*$i}px; }
  .pt-#{50*$i} { padding-top: #{50*$i}px; }

  .pl-#{5*$i} { padding-left: #{5*$i}px; }
  .pl-#{50*$i} { padding-left: #{50*$i}px; }

  .pr-#{5*$i} { padding-right: #{5*$i}px; }
  .pr-#{50*$i} { padding-right: #{50*$i}px; }

  .pb-#{5*$i} { padding-bottom: #{5*$i}px; }
  .pb-#{50*$i} { padding-bottom: #{50*$i}px; }
}

// General class for padding.( unit : em )
// --------------------------------------------------
// value : p{t,l,r,b}-0em ~ p{t,l,r,b}-50em
// step : 1
// --------------------------------------------------
@for $i from 0 through 50 {
  .pt-#{$i}em { padding-top: #{$i}em; }

  .pl-#{$i}em { padding-left: #{$i}em; }

  .pr-#{$i}em { padding-right: #{$i}em; }

  .pb-#{$i}em { padding-bottom: #{$i}em; }
}

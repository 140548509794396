.c-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1020;
  font-size: 16px;

  &[data-modal] {
    display: none;
  }

  &__close {
    position: absolute;
    @include tvw(5);
    @include rvw(5);
    @include wvw(30);
    @include hvw(30);
    border: 1px solid rgba($truewhite,.3);
    background: transparent;

    &::before,
    &::after {
      content: '';
      display: block;
      background: $truewhite;
      width: 40%;
      height: 1px;
      transform: rotate(45deg);
      position: absolute;
      top: 50%;
      right: 50% - 20%;
    }

    &::after {
      transform: rotate(-45deg);
    }

    @include hover {
      background: $black;
      border-color: transparent;
    };
  }

  // &:not(.is-visible) {
  //   display: none;
  // }

  &__overlay {
    @include flex(ce,ce);
    width: 100%;
    height: 100%;
    padding: 20px;
    background: rgba($darkcharcoal,.97);

    @include ltXXS {
      padding: 4vw;
    };
  }

  &__inner {
    background: $trueblack;
    box-shadow: 0 0 20px 0 rgba($trueblack,.3);
    transform: translateY(20%) scale(.8);
    transition-property: transform,opacity;
    transition-duration: .2s;

    &.is-visible {
      transform: translateY(0) scale(1);
    }
  }
}

.l-footer-free-dial {
  position: relative;
  overflow: hidden;
  max-width: 100%;

  &__stripe {
    position: absolute;
    top: 15px;
    left: 0;
    width: 100%;
    z-index: 0;
  }

  &__over-content {
    position: relative;
    z-index: 1;
  }

  &__title {
    @include pvw(10,10,3);
  }

  &__title-small {
    @include fsvw(10);
    display: inline-block;
    border-radius: .4em;
    background: #f384bc;
    color: $truewhite;
    padding: 0 .5em;
    font-weight: bold;
  }

  &__title-strong {
    display: block;
    @include fsvw(16);
    @include NotoSerif;
    color: $charcoal;
    font-weight: bold;

    & > em {
      font-size: 120%;
    }
  }

  &__badge {
    background: $accent;
    color: $truewhite;
    @include flex(ce,ce);
    @include wvw(85);
    @include hvw(85);
    border-radius: 50%;
    position: absolute;
    @include tvw(-10);
    margin-top: 10px;
    right: 0;
    text-align: center;
    @include pvw(10,0);
    transform: scale(.8);
  }

  &__badge-small {
    @include fsvw(10);
    line-height: 1.3;
    letter-spacing: 0.01em;
    font-weight: bold;
  }

  &__badge-strong {
    @include fsvw(27);
    @include NotoSerif;
    line-height: 1.3;
    font-weight: bold;
  }

  &__call {
    @include flex(ce,ce);
    color: inherit;
    line-height: 1;
    @include prvw(5);
  }

  &__call-icon {
    @include fsvw(42);
    font-weight: normal;
    line-height: 0;
    margin-right: .3em;
    color: $accent;
    display: block;
  }

  &__call-number {
    @include fsvw(48);
    @include EB;
    letter-spacing: 0.01em;
    font-weight: normal;
    display: block;
  }
}

.p-workflow {
  @include pvw(0,0);

  &--beginners {
    @include pvw(0,0,40);
  }

  &__beginners-title {
    @include wvw(266);
    @include mbvw(20);
  }

  &__row {
    @include mbvw(20);
    @include pvw(5,0,5,5);
  }
}

// --------------------------------------------------
// variables
// --------------------------------------------------

$sansSerif: 'Yu Gothic', YuGothic, '游ゴシック', '游ゴシック体', 'メイリオ', Meiryo, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'ＭＳ Ｐゴシック', sans-serif;
$serif: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", "Sawarabi Mincho", serif;
$monospace: Monaco, Consolas, 'Courier New', Courier, monospace;
$NotoSans: 'Noto Sans JP', $sansSerif;
$NotoSerif: 'Noto Serif JP', $serif;
$Roboto: 'Roboto', $sansSerif;
$RobotoSlab: 'Roboto Slab', $serif;
$EB: 'EB Garamond', $serif;

// --------------------------------------------------
// mixins
// --------------------------------------------------

@mixin ls($ls:.08) {
  letter-spacing: #{1.0*$ls}em;
  @include ltMD { letter-spacing: #{.8*$ls}em; };
  @include ltSM { letter-spacing: #{.4*$ls}em; };
  @include ltXS { letter-spacing: #{.3*$ls}em; };
  @include ltXXS { letter-spacing: #{.2*$ls}em; };
}

@mixin sansSerif($weight:500, $ls:.08) {
  font-family: $sansSerif;
  font-weight: $weight;
  letter-spacing: #{$ls}em;
};

@mixin serif($weight:400, $ls:.16) {
  font-family: $serif;
  font-weight: $weight;
  letter-spacing: #{$ls}em;
};

@mixin monospace($weight:400, $ls:0) {
  font-family: $monospace;
  font-weight: $weight;
  letter-spacing: #{$ls}em;
};

@mixin NotoSans($weight:400, $ls:.04) {
  font-family: $NotoSans;
  font-weight: $weight;
  letter-spacing: #{$ls}em;
};

@mixin NotoSerif($weight:600, $ls:.08) {
  font-family: $NotoSerif;
  font-weight: $weight;
  letter-spacing: #{$ls}em;
};

@mixin Roboto($weight:500, $ls:.16) {
  font-family: $Roboto;
  font-weight: $weight;
  letter-spacing: #{$ls}em;
};

@mixin RobotoSlab($weight:500, $ls:.16) {
  font-family: $RobotoSlab;
  font-weight: $weight;
  letter-spacing: #{$ls}em;
};

@mixin EB($weight:500, $ls:.16) {
  font-family: $EB;
  font-weight: $weight;
  letter-spacing: #{$ls}em;
};

@mixin fs($size, $base: 10) {
  font-size: $size + px;
  font-size: ($size / $base) + rem;
}

.p-place-detail {

  &__title {
    position: relative;

    &::before,
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 50px;

      @include ltXS {
        height: 7.8125vw;
      };

      @include ltXXXS {
        height: 25px;
      };
    }

    &::before {
      background: url('../img/place/frame-place-name-top.png');
      background-size: cover;
    }

    &::after {
      background: url('../img/place/frame-place-name-bottom.png');
      background-size: cover;
    }
  }

  &__title-item {
    display: block;
    width: 95.8%;
    @include fsvw(18);
    color: $charcoal;
    @include NotoSerif;
    text-align: center;
    line-height: 1.4;
    @include mvw(-7,auto);
    position: relative;
    padding: 0 1em;
    letter-spacing: .05em;

    &::before,
    &::after {
      content: '';
      display: block;
      width: 1px;
      height: 50%;
      background: #a4834e;
      position: absolute;
      top: 25%;

      @include ltXXXS {
        height: 25px;
      };
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }
  }

  &__image {
    width: 100%;

    & > img {
      width: 100%;
    }
  }

  &__lead {
    @include fsvw(12);
    @include pvw(15);
    background: url('../img/common/ptn-2.png');
    background-size: 60px 60px;
    color: $charcoal;
  }

  &__table {
    display: block;
    color: $charcoal;

    tbody {
      display: block;
    }

    tr {
      text-align: left;
      display: block;
      @include pvw(5,0);
      border-bottom: 1px dotted rgba($charcoal,.1);
    }

    th {
      text-align: left;
      display: block;
      @include fsvw(9);
      font-weight: bold;
    }

    td {
      text-align: left;
      display: block;
      @include fsvw(11);

      a {
        color: inherit;
        text-decoration: underline;
      }
    }
  }

  &__info {
    @include pvw(10,15);
    box-shadow: 0 0 10px rgba($trueblack,.1);
  }

  &__button-conatiner {
    @include pvw(30,15);
    background: url('../img/common/ptn-2.png');
    background-size: 60px 60px;
    color: $charcoal;
    box-shadow: 0 0 10px rgba($trueblack,.1) inset;
  }
}

.p-article-post {
  color: $charcoal;
  @include pvw(5,0);

  & > * {
    @include mvw(15);
    @include fsvw(13);
  }

  & > img,
  iframe {
    display: block;
    @include mvw(10,0);
    width: 100%;
  }

  & > * > img.alignnone {
    width: 120%;
    margin-left: -10%;
    margin-right: -10%;
    max-width: initial;
  }

  & > * > iframe {
    width: 120%;
    margin-left: -10%;
    margin-right: -10%;
    max-width: initial;
  }
}

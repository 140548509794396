.p-index-for-beginners {
  @include pvw(20);

  &__title {
    @include NotoSerif;
    @include fsvw(21);
    text-align: center;
    color: $charcoal;
    font-weight: 300;
    letter-spacing: .01em;
  }

  &__link {
    display: block;
    @include wvw(150);
    @include mvw(10,auto);
  }
}

.c-page-header {
  @include ptvw(52 + 20);
  position: relative;
  z-index: 19;
  background-color: $truewhite;

  &--grad {
    @include gradbg(rgba($cs01,.2),rgba($cs04,.2));
  }

  &__lead {
    position: relative;
    z-index: 1;

    // &--feature {}
  }

  &__lead-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include flex(ce,ce);
    @include NotoSerif;
    @include fsvw(12);
    color: $charcoal;
    @include pvw(0,15);
  }

  &__blog-handwrite {
    margin-left: auto;
    position: relative;
    @include bvw(-15);
    @include wvw(55);
  }

  &__handwrite {
    margin-left: auto;
    position: relative;

    &--registry {
      @extend .c-page-header__handwrite ;
      @include bvw(-15);
      @include wvw(100);
    }

    &--contact {
      @extend .c-page-header__handwrite ;
      @include bvw(-15);
      @include wvw(83);
    }
  }

  &__cover {
    position: relative;
  }

  &__cover-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include flex(ce,ce);
    @include NotoSerif;
    @include fsvw(16);
    text-shadow: $truewhite 1px 1px 3px,
    $truewhite -1px 1px 3px,
    $truewhite 1px -1px 3px,
    $truewhite -1px -1px 3px;
    color: $charcoal;

    &--left {
      justify-content: flex-start;
      @include plvw(20);
    }

    &--left-w {
      justify-content: flex-start;
      @include plvw(20);
      text-shadow: none;
      color: $truewhite;
    }
  }

  &__badge-beginners {
    position: absolute;
    z-index: 1;
    @include wvw(103);
    @include rvw(5);
    @include bvw(-12);
  }

  &__feature-lead {
    position: absolute;
    z-index: 1;
    width: 100%;
    left: 0;
    top: 0;
  }

  &__feature-lead-text {
    position: relative;
    z-index: 2;
  }

  &__feature-title {
    position: absolute;
    z-index: 3;
    width: 100%;
    left: 0;
    bottom: 0;
    text-align: center;
    @include NotoSerif;
    line-height: 1.6;
    color: $accent;
    @include fsvw(22);
  }
}

.p-company-profile {
  color: $charcoal;
  @include pvw(25,20);

  &__title {
    @include fsvw(21);
    @include NotoSerif;
    text-align: center;
    line-height: 1.4;
    @include pvw(10,0);

  }

  &__title-small {
    display: block;
    @include fsvw(12);
    @include Roboto;
    transform: scale(.7);
  }

  &__table {
    display: block;

    tbody {
      display: block;
    }

    tr {
      text-align: left;
      display: block;
      @include pvw(5,0);
      border-bottom: 1px dotted rgba($charcoal,.1);
    }

    th {
      text-align: left;
      display: block;
      @include fsvw(9);
      font-weight: bold;
    }

    td {
      text-align: left;
      display: block;
      @include fsvw(11);

      a {
        color: inherit;
        text-decoration: underline;
      }
    }
  }

  &__map-wrap {
    @include mtvw(10);
  }

  &__map {
    display: block;
    width: 100%;
    @include hvw(200);
  }
}

.l-main {
  min-height: 50vh;
  position: relative;
  z-index: 10;
  background: $truewhite;
  overflow: hidden;
  max-width: 100%;

  &--shift-to-bottom {
    @include ptvw(52 + 20);
  }
}

.p-article-header {
  display: block;
  @include pvw(15);
  color: $charcoal;
  position: relative;

  &--archive {
    @include pvw(0);

    & > img {
      width: 100%;
      @include hvw(200);
      @include object-fit;
    }
  }

  &::after {
    content: '';
    display: block;
    width: 100px;
    height: 1px;
    background: rgba($charcoal,.2);
    position: absolute;
    bottom: 0;
    left: calc(50% - 50px);
  }

  &--archive::after {
    content: none;
    display: none;
  }

  &__thumb {
    width: 100%;
    @include hvw(224);
    @include object-fit;
  }

  &__posttime {
    font-weight: normal;
    @include fsvw(9);
  }

  &__postname {
    @include fsvw(18);
    @include NotoSerif;
    line-height: 1.2;
    @include mvw(10,0);

    &--archive {
      @include fsvw(14);
      @include mvw(5,0,15);
    }
  }

  &__detail {
    @include pvw(15,20,5,15);
    position: relative;

    &::after {
      @include fsvw(10);
      content: '';
      display: block;
      width: .6em;
      height: .6em;
      border-top: 1px solid;
      border-right:  1px solid;
      transform: rotate(45deg);
      position: absolute;
      top: calc(50% - .3em);
      right: 1em;
    }
  }
}

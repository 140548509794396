.c-logo {
  $this: &;
  @include flex(ce,ce);
  height: 4em;
  letter-spacing: 0;
  margin-left: 3.5em;
  margin-right: 3.5em;

  @include ltMD {
    margin-left: 2em;
    margin-right: 2em;
  };

  @include ltXS {
    height: 5em;
    margin-left: 0;
    margin-right: 0;
    justify-content: flex-start;
  };

  &__body {
    @include fs(24);
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    color: currentColor;
    letter-spacing: 0;
    text-decoration: none;

    @include hover {
      #{$this}__symbol {
        transform: rotate(360deg);
      }
    };
  }

  &__symbol {
    @include fs(24);
    width: 2em;
    height: 2em;
    margin-right: .3em;
    font-weight: normal;
    @include ease(.6s);
  }

  &__text {
    font-weight: normal;
    white-space: nowrap;
    // letter-spacing: .1em;
    letter-spacing: -.02em;
    @include flex(ce,fs);

    em {
      display: block;
      text-indent: -.1em;

      &:first-letter {
        margin-right: -.1em;
      }
    }
  }

  &__tag-line {
    @include fs(10);
    width: 24em;
    margin-left: 2em;
    line-height: 1.5em;

    @include ltSM {
      display: none;
    };
  }
}

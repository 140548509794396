.p-feedback {
  $this: &;
  color: $charcoal;

  &--index {
    @include pbvw(30);
  }

  &__body {}

  &__item {
    @include pvw(10);
    @include pvw(30,15);
    position: relative;

    &:not(:first-child) {
      @include mvw(-60,0,0);
    }
  }

  &__lead {
    @include fsvw(11);
    width: 65%;
    @include mvw(10,0,40);

    #{$this}--index & {
      width: 80%;
    }

    #{$this}__item:nth-child(2n) & {
      margin-left: 35%;

      #{$this}--index & {
        margin-left: 20%;
      }
    }

    &--last {
      margin-bottom: 0 !important;
    }

    em {
      font-weight: bold;
      background: linear-gradient(transparent 60%, rgba($accent,.2) 60%);
    }
  }

  &__label-inner {

    #{$this}__item:nth-child(2n) & {
      @include mrvw(10);

      #{$this}--index & {
        @include wvw(240);
        @include mrvw(60);
      }
    }

    #{$this}__item:nth-child(2n-1) & {
      @include mlvw(10);

      #{$this}--index & {
        @include wvw(240);
        @include mlvw(60);
        justify-content: flex-start;
      }
    }
  }

  &__image {
    position: absolute;
    width: 35%;
    @include flex(ce,ce);
    @include tvw(-10);
    right: 0;
    z-index: 3;

    #{$this}--index & {
      width: 30%;
      margin-right: -4%;
    }

    #{$this}__item:nth-child(2n) & {
      right: auto;
      left: 0;

      #{$this}--index & {
        margin-right: 0;
        margin-left: -4%;
      }
    }

    & > img {
      @include wvw(100);
    }
  }
}

.aigis-glyph {
  * {
    box-sizing: border-box;
  }
  &__title {
    font-size: 40px;
    border-bottom: 2px solid #eee;
    padding-bottom: 0.3em;
    margin-top: 1.5em;
    line-height: 1.225;
    font-weight: 300;
  }
  &__body {
    margin: 2em 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    width: 32%;
    margin-right: 2%;
    margin-bottom: 1em;
    position: relative;

    @include gtMD {
      &:nth-of-type(3n) {
        margin-right: 0;
      }
    };

    @include ltSM {
      width: 48%;

      &:nth-of-type(2n) {
        margin-right: 0;
      }
    };

    @include ltXS {
      width: 100%;
      margin-right: 0;
      margin-bottom: 1.5em;
    };


    &--6col {
      width: 9%;

      &:nth-of-type(3n) {
        margin-right: 2%;
      }
      &:nth-of-type(9n) {
        margin-right: 0;
      }
    }

    &[class*="aigis-glyph__item--preset"] {
      &:before {
        content: "PRESET";
        display: block;
        background: #333;
        color: #fff;
        font-size: 10px;
        padding: .2em 1em 0em 1em;
        position: absolute;
        right: 0;
        top: -1.2em;
        border-radius: 4px 4px 0 0;
      }
    }
  }

  &__panel {
    background: #f9f9fd;
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    overflow: hidden;
  }

  &__preview {
    width: 100px;
    // background: #eee;
    padding: 10px 0;
    text-align: center;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background: url( 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAASklEQVRYR+3WIQ4AMAhDUVDsFhOIJdz/cENth6jA/PomzVP1zP1MSMQS2mbOAAQQQAABBBAYF6g60h/ovtofYAACCCCAAAIITAt80QxeAU/PMlsAAAAASUVORK5CYII=' );
    -webkit-background-size: 16px;
    background-size: 16px;
    color: #ccc;

    &--full {
      width: 100%;
    }

    i {
      margin-top: 5px;
    }

    .glyph-codepoint {
      display: block;
      width: 100%;
      font-size: 10px;
      line-height: 1.4;
      opacity: .5;
      &--small {
        font-size: 11px;
        opacity: 1;
        letter-spacing: .1em;
      }
    }
  }
  &__detail {
    width: calc( 100% - 99px );
    background: #272822;
    border-left: 1px solid #333;

    .glyph-name {
      font-size: 11px;
      line-height: 1.4;
      margin: 0;
      color: #fff;
      background: #333;
      padding: 0 .5em 0 0;
      display: flex;
      align-items: center;
      line-height: 1;

      .lender {
        font-size: 13px;
        background: #222;
        width: 2.2em;
        height: 2.2em;
        line-height: 2.5em;
        text-align: center;
        margin-right: .5em;
      }
    }
  }
  &__pre {
    position: relative;
    color: #fff;
    font-size: 12px;
    margin: 0;
    padding: 0 1em;
    border-top: 1px solid #333;

    small {
      font-size: 10px;
      position: absolute;
      top: 2px;
      right: 10px;
      display: block;
      line-height: 1;
      opacity: .5;
    }
  }
  &__code {
    display: block;
    padding-right: 1em;
    display: block;
    padding: 15px 0;
    overflow-x: scroll;
  }
  &__codeInner {
    display: block;
    width: auto;
    white-space: nowrap;
    position: relative;
    z-index: 2;
    padding-right: 1em;
    margin-right: 1em;
    text-shadow: 0 1px rgba(0, 0, 0, 0.3);
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;

    .red {color: #f92672;}
    .org {color: coral;}
    .lim {color: #a6e22e;}
    .ylw {color: #e6db74;}
  }
}

/*
---
name: Collapse
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Component
---

Collapse は、トリガー要素となる `.c-collapse-trigger]` と、ターゲット要素となる `.c-collapse-target` で構成されます。

主に、カスタムデータ属性 `[data-trigger]` の付与による JS によるトグル機能の利用を想定しています。

```html
<div class="c-collapse c-container">
  <div class="c-collapse-trigger" data-trigger="[&quot;01&quot;]">トリガー01</div>
  <div class="c-collapse-target" data-target="[&quot;01&quot;]">ターゲット01</div>
</div>
```
```jade
//- Pug
.c-collapse.c-container
  .c-collapse-trigger(data-trigger='["01"]') トリガー01
  .c-collapse-target(data-target='["01"]') ターゲット01
```

JS によるトグル機能については、 [JS-Transpile#Toggle](../../../Documentation/Preprocessor/JS-Transpile/index.html#toggle) のドキュメントを参照してください。

```html
<div class="c-collapse c-container">
  <div class="c-collapse-trigger" data-trigger="[&quot;02&quot;,&quot;slide&quot;]">トリガー02（スライド）</div>
  <div class="c-collapse-target" data-target="[&quot;02&quot;]">ターゲット02</div>
</div>
```
```jade
//- Pug
.c-collapse.c-container
  .c-collapse-trigger(data-trigger='["02","slide"]') トリガー02（スライド）
  .c-collapse-target(data-target='["02"]') ターゲット02
```

*/

.c-collapse {
  margin-bottom: 2em;
}

.c-collapse-trigger {
  padding: .2em 1em;
  background: transparent;
  border-bottom: 2px solid $accent;

  &:hover {
    // border-bottom-color: $cs01;
    background: rgba($accent,.1);
  }

  &.is-active {
    background: rgba($accent,.1);
  }
}
.c-collapse-target {
  padding: 0;

  &.is-visible {
  }
}

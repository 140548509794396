.p-bottom-fix {
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 640px;
  min-width: 320px;
  background-image: url('../img/common/bottom-fix/bg.png');
  background-size: 2px 2px;
  @include flex(st,sb);
  @include pvw(10);

  @include pc_safari {
    // z-index: 10000;
  };

  @include gtSM {
    left: calc(50% - 320px);
  };

  & > * {
    // width: 32%;
    &:first-child {
      width: 34%;
    }

    &:not(:first-child) {
      width: 31%;
    }
  }

  &__tel {
    color: $truewhite;
    letter-spacing: 0;
  }

  &__tel-label {
    @include fsvw(13);
    position: relative;
    background: transparent;
    background: $truewhite;
    border-radius: .25em;
    overflow: hidden;
    line-height: 0;
    @include hvw(12);
    @include flex(ce,ce);
    @include mbvw(5);

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @include gradbg($cs01,$cs04);
      z-index: 0;
      transform: scale(1.2);
      @include opacity(.8);
    }

    & > small {
      text-align: center;
      display: block;
      position: relative;
      z-index: 1;
      transform: scale(.7);
      margin-left: -20%;
      margin-right: -20%;
      text-shadow: 1px 1px 1px rgba($trueblack,.2);
    }
  }

  &__entry {
    display: block;
    text-align: center;
    line-height: 1.2;
    @include fsvw(14);
    letter-spacing: 0;
    color: $truewhite;
    background: #e65a97;
    border-radius: .3em;
    @include flex(ce,ce);
    font-weight: bold;
    text-shadow: 1px 1px 1px rgba($trueblack,.2);
    border: 2px solid rgba($truewhite,.2);
  }

  &__line {
    display: block;
    text-align: center;
    line-height: 1.2;
    @include fsvw(10);
    letter-spacing: 0;
    color: $truewhite;
    background: #00bb00;
    border-radius: .4em;
    @include flex(ce,ce);
    flex-flow: column;
    font-weight: bold;
    text-shadow: 1px 1px 1px rgba($trueblack,.2);
    border: 2px solid rgba($truewhite,.2);

    .glyph {
      font-size: 2em;
      font-weight: normal;
      transform: scale(1.2);
    }
  }
}

.p-demo {
  background: rgba($grey,.1);
  padding: 2em;
  border: 1px solid rgba($grey,.2);

  &__dummy {
    min-height: calc(100vh - 100px);
    @include flex(ce,ce);

    &--l {
      @extend .p-demo__dummy ;
      min-height: calc(60vh - 100px);
    }

    &--m {
      @extend .p-demo__dummy ;
      min-height: calc(50vh - 100px);
    }

    &--s {
      @extend .p-demo__dummy ;
      min-height: calc(40vh - 100px);
    }
  }
}

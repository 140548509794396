.p-article-category {

  & > a {
    @include fsvw(10);
    display: inline-block;
    padding: 0 1em;
    border-radius: 1.5em;
    color: $truewhite;
    font-weight: 500;
    margin: .1em;
    background: $charcoal;
  }
}

.p-ribbon-pink {
  $this: &;
  @include hvw(25);
  overflow: hidden;
  @include NotoSerif;

  &__inner {
    background: $accent;
    color: $truewhite;
    @include fsvw(13);
    @include wvw(300);
    margin: 0 auto;
    padding: 0 1em;
    position: relative;
    @include hvw(25);
    text-align: center;
    font-weight: bold;
    @include flex(ce,ce);

    &::before,
    &::after {
      position: absolute;
      content: '';
      display: block;
      width: 0;
      height: 0;
      z-index: 3;
      @include bwvw(12.5);
      border-style: solid;
      border-color: $accent;
      top: 0;
      transform: scale(1.1);
    }

    &::before {
      @include lvw(-12.5);
      border-left-color: transparent;
    }

    &::after {
      @include rvw(-12.5);
      border-right-color: transparent;
    }
  }
}

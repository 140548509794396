#tinymce,
.c-editor-content {
  color: $charcoal;

  @include clearfix;

  > * {
    @include mtvw(10);
    @include mbvw(10);
    line-height: 2em;

    // ----------------------------------------
    // Phrasing Content
    // ----------------------------------------

    a {
      font-weight: 600;
      border-bottom: 1px dashed;
      color: $accent;
      @include external;
    }

    strong {
      font-weight: 900;
    }

    em {
      font-style: italic;
      margin-right: .2em;
    }

    code {
      background: $white;
      color: DarkCyan;
      @include fsvw(10);
      display: inline-block;
      padding: .1em 1em;
      vertical-align: baseline;
      font-weight: bold;
      border-radius: .3em;
      font-family: monospace;
    }
  }

  // ----------------------------------------
  // Heading
  // ----------------------------------------

  $fs: 36;
  $rate: 0.09;
  h2, h3, h4, h5, h6 {
    font-weight: 300;
    @include mtvw(10);
    @include mbvw(10);
    line-height: 1.2;

    & > small {
      font-weight: 400;
      font-size: 50%;
      @include opacity(.5);
    }

    &:first-child { margin-top: 0; }
  }

  h2 {
    font-size: 200%;
  }

  h3 {
    font-size: 180%;
  }

  h4 {
    font-size: 165%;
  }

  h5 {
    font-size: 140%;
  }

  h6 {
    font-size: 120%;
  }

  // ----------------------------------------
  // Paragraph
  // ----------------------------------------

  p {
    @include fsvw(11);

  }

  // ----------------------------------------
  // Table
  // ----------------------------------------

  table {

    tr {}

    th, td {
      border: 1px solid $lightsilver;
      padding: .5em 1em;
    }

    th {
      background: $white;
    }

    td {}
  }

  // ----------------------------------------
  // Horizontal Line
  // ----------------------------------------

  hr {
    display: block;
    border: none;
    height: 1px;
    background: rgba($grey,.1);
  }

  // ----------------------------------------
  // Lists
  // ----------------------------------------

  ul, ol {
    @include fsvw(11);
    padding-left: 1.5em;

    li {
      margin: .5em 0;
      line-height: 1.6;
    }
  }

  ul {
    list-style-type: disc;
  }

  ul,ol {

    > li ul,
    > li ol {
      padding-left: 3em;
    }
  }

  ul ul,
  ol ul {
    list-style-type: circle;
  }

  ol ol ul,
  ol ul ul,
  ul ol ul,
  ul ul ul {
    list-style-type: square;
  }

  ol {
    display: block;
    list-style-type: decimal;
  }

  ol ol,
  ul ol {
    list-style-type: lower-roman;
  }

  ul ul ol,
  ul ol ol,
  ol ul ol,
  ol ol ol {
    list-style-type: lower-alpha;
  }

  // ----------------------------------------
  // Image
  // ----------------------------------------

  img {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  // ----------------------------------------
  // Inline Frame
  // ----------------------------------------

  iframe {
    max-width: 100%;
  }

  // ----------------------------------------
  // Preformatted text
  // ----------------------------------------

  pre {
    font-family: monospace;
  }

  // ----------------------------------------
  // Blockquote
  // ----------------------------------------

  blockquote {
    font-size: 90%;
    border-left: 1em solid rgba($grey,.1);
    padding-left: 2em;
    clear: both;
    line-height: 1.6;

    & > * {
      margin-top: 2rem;
      margin-bottom: 2rem;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// ----------------------------------------
// Post Header
// ----------------------------------------
.c-editor-header {
  padding: 1em 0;
  border-bottom: 2px solid rgba($grey,.2);
  margin-bottom: 2em;

  &__postinfo {
    @include flex(ce);
    color: $grey;
  }

  &__date {
    @include fs(14);
    @include flex(ce);
  }

  &__date-body {
    @include fs(12);
    margin-left: .2em;
    font-weight: 700;
  }

  &__title {
    @include fs(24);
    font-weight: 400;
  }

  &__cat {
    margin-left: 1em;
    @include fs(14);
    text-transform: uppercase;
    @include flex(ce);

    ul {
      margin-left: .5em;
      @include flex(ce);
    }
  }

  &__tag {
    @include fs(12);
    color: $grey;
    @include flex(ce);

    a {
      display: inline-block;
      padding: 0 1em;
      border: 1px solid rgba($grey,.2);
      border-radius: 4px;
      margin: .2em;
      color: currentColor;

      @include hover {
        background: $lightgrey;
        color: $black;
        border-color: transparent;
      };
    }
  }

  &__tag-label {
    @include fs(13);
    text-transform: uppercase;
  }

  &__tag-body {}

  &__author {
    @include fs(14);
    @include flex(ce);
    margin-bottom: .5em;

    img {
      max-width: 3em;
      min-width: 3em;
      height: 3em;
      margin-right: .5em;
    }
  }

  &__taxonomies {
    @include fs(12);
    color: $grey;
    @include flex(ce);
    margin-top: 1em;
  }

  &__taxonomy {
    @include flex(ce);
  }

  &__tax-name {
    border: 1px solid rgba($grey,.2);
    border-radius: 1em;
    padding: 0 1em;
    @include fs(10);
    font-weight: 600;
  }

  &__term-name {
    padding: 0 0.5em;

    a {
      color: currentColor;
    }
  }

  &__duration {
    @include fs(12);
    color: $grey;
    margin-top: 1em;
  }
}

.p-index-office-view {

  &__title {
    @include gradtypes;
    @include Roboto;
    @include fsvw(30);
    @include pvw(10);
    text-align: center;
    letter-spacing: 0;
  }

  &__viewer {}

  &__viewer-item {
    display: block;
    max-width: 100%;
    @include hvw(250);
  }
}

.p-beginners-relief {
  background: #fff6f8;
  padding: 0;

  &__incite {
    // border-bottom: 35px solid $truewhite;
    border-bottom: 35vw*.2875 solid $truewhite;

    @include gtSM {
      border-bottom-width: 70px;
    };

    @include ltXXXS {
      border-bottom-width: 35px;
    };
  }

  &__title {
    @include wvw(259);
    @include mvw(-15,auto,0);
    position: relative;
    z-index: 1;
  }

  &__image {}

  &__lead {
    @include fsvw(11);
    @include pvw(10);
  }

  // &__certificate {
  //   @include flex(fs,sb);
  //   @include pvw(10);
  //
  //   & > img {
  //     width: calc(50% - 5px);
  //   }
  // }
}

/*
---
name: Position
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Utility
---
 *
ほげほげ説明文
 *
```css
.u-position {
  color: aaa;
  display: block;
}
```

*
ほげほげな説明文
*
```css
.u-position {
  &--hoge {
    なんか
  }
}
```
*/
.u-position {}

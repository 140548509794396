@media screen and (max-width: 640px) {
  .u-fs {
    font-size: 10px;
    transform-origin: left center;
    position: relative;

    &--8 {
      @extend .u-fs ;

      @include chrome {
        $rate: .8 !default;
        transform: scale($rate);
        margin-right: 100% - 100%/$rate;
      };
    }
  }
}

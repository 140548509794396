$headerH: 4.5em !default;

.l-header {
  $this: &;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 640px;
  min-width: 320px;
  z-index: 1000;
  color: $truewhite;
  transition: background-color .15s ease-in-out;
  // height: 4.5em;
  @include hvw(52+20);
  background: $trueblack;

  @include gtSM {
    left: 50%;
    margin-left: -320px;
  };

  @include admin-bar {
    top: 32px;

    @media screen and ( max-width: 782px ) {
      top: 46px;
    }
  };

  .index & {
    background: transparent;
    color: $trueblack;
  }

  &__tagline {
    background: $white;
    color: $main;
    @include fsvw(10);
    @include hvw(20);
  }

  &__tagline-inner {
    @include flex(ce,ce);
    padding: 1px 0;
    transform: scale(.8);
    margin-left: -15%;
    margin-right: -15%;

    & > h1 {
      margin-left: 2em;
    }
  }

  @include ltXS {
    .is-entire-nav-target & {
      background: $black;
    }
  };

  a {
    @include hover {
      color: currentColor;
    };
  }

  &__body {
    @include hvw(52);
    @include pvw(0,10);
    @include flex(ce,fs);
  }
}

@media screen and (max-width: 600px) {
  #wpadminbar {
    @include admin-bar {
      position: fixed;
    }
  }
}

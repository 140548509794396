.p-mens-recruit-requirements {
  @include pvw(10);
  color: $charcoal;

  &__body {
    padding: 0;
  }

  &__item {
    @include flex(ce,fs,false);
    @include mtvw(20);
  }

  &__icon {
    @include wvw(50);
    @include mnwvw(50);
  }

  &__icon-item {
    background: rgba($trueblack,.1);
    @include wvw(50);
    @include mnwvw(50);
    @include hvw(50);
    border-radius: 50%;
    @include flex(ce,ce);
    color: $black;
    @include fsvw(30);
  }

  &__detail {
    @include fsvw(12);
    width: 100%;
    margin-left: 1em;
  }

  &__name {
    @include fsvw(10);
    letter-spacing: .08em;
    line-height: 1.4;
    font-weight: bold;
  }

  &__salary {
    @include fsvw(13);
    margin-top: .3em;
  }

  // &__title {
  //   padding: 30px 0;
  // }
  //
  // &__contact {
  //   text-align: center;
  //   margin: 20px 0 0 0;
  //   color: #3c3c3c;
  //   font-weight: bold;
  //   font-size: 15px;
  //   background-image: url(/_assets/img/mens-recruit/contact-bg.png);
  //   background-repeat: no-repeat;
  //   background-position: center;
  //   background-size: 100%;
  //   line-height: 4.3em;
  // }
  //
  // &__tel {
  //   text-align: center;
  //   font-size: 37px;
  //   color: #e72f8d;
  //   font-weight: 300;
  //   width: calc(100% - 40px - 15px);
  // }
  //
  // &__24hours {
  //   text-align: center;
  //   font-size: 11px;
  //   color: #3c3c3c;
  //   margin-bottom: 15px;
  // }
}

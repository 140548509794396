$menuWidth: 300px;

.l-navigation {
  @include NotoSerif;
  position: fixed;
  z-index: 1020;
  top: 0;
  left: 100%;
  width: $menuWidth;
  height: 100%;
  overflow-y: scroll;
  @include ease(.3s);

  &.is-visible {
    left: calc(100% - #{$menuWidth});
  }

  @include admin-bar {
    top: 32px;

    @media screen and ( max-width: 782px ) {
      top: 46px;
    }
  };

  @include gtSM {
    width: 0;
    overflow-x: hidden;
    left: calc(50% + 320px);

    &.is-visible {
      width: $menuWidth;
      left: calc(50% + 320px - #{$menuWidth});
    }
  };

  &__inner {
    width: $menuWidth;
    background: rgba($cs03,.7);
    min-height: 100%;
    color: $truewhite;
  }

  &__title {
    text-align: center;
    font-size: 12px;
    padding: 10px;
    border-bottom: 1px solid rgba($truewhite,.5);
  }

  &__body {
    padding-left: 15px;
  }

  &__item {
    border-bottom: 1px solid rgba($truewhite,.3);
  }

  &__link {
    display: block;
    padding: 10px 10px 10px 0;
    color: inherit;
    font-size: 13px;

    @include hover {
      color: inherit;
    };
  }
}

/*
---
name: Text
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Utility
---

*/

.u-text {
  margin-top: 2rem;
  margin-bottom: 2rem;

  &__left {
    text-align: left;
  }

  &__center {
    text-align: center;
  }

  &__right {
    text-align: right;
  }
}

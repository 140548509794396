.c-breadcrumb-container {}

.c-breadcrumb {
  padding: 1em 2em;
  @include flex(ce,fs,false);
  position: relative;
  z-index: 100;

  @include gtSM {
    border-top: 1px solid rgba($grey,.1);
  };

  @include ltXS {
    overflow-x: scroll;
    border-bottom: 1px solid rgba($grey,.1);
  };

  &__item {
    @include fs(12);
    margin-right: 1em;
    @include flex(ce,fs,false);

    @include ltXS {
      white-space: nowrap;
    };

    &:not(:nth-of-type(1))::before {
      content: "";
      display: inline-block;
      min-width: .3em;
      height: .3em;
      transform: rotate(45deg);
      border-top: 2px solid currentColor;
      border-right: 2px solid currentColor;
      margin-right: 1.3em;
      @include opacity(.3);
    }

    &--dot {
      &::before {
        min-width: .3em;
        height: .3em;
        border-radius: 50%;
        border: none;
        background: currentColor;
      }
    }
  }

  &__link {
    color: currentColor;
    font-weight: 500;
    @include opacity(.7);

    &:hover {
      @include opacity(1);
    }

    > span {}
  }
}

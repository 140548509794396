// ========================================
//  Variables
// ========================================

// ----------------------------------------
//  ブレイクポイント
// ----------------------------------------

$xxsMinSize: 320px;
$xsMinSize: 480px;
$smMinSize: 641px;
$mdMinSize: 992px;
$lgMinSize: 1200px;
$xlMinSize: 1600px;
$xxxsBreak: $xxsMinSize - 1px;
$xxsBreak: $xsMinSize - 1px;
$xsBreak: $smMinSize - 1px;
$smBreak: $mdMinSize - 1px;
$mdBreak: $lgMinSize - 1px;
$lgBreak: $xlMinSize - 1px;

// ========================================
//  Mixins
// ========================================

// ----------------------------------------
// 対象サイズ未満のみ
// ----------------------------------------

@mixin ltXXXS() {
  @media screen and (max-width: $xxxsBreak) {
    @content;
  }
}
@mixin ltXXS() {
  @media screen and (max-width: $xxsBreak) {
    @content;
  }
}
@mixin ltXS() {
  @media screen and (max-width: $xsBreak) {
    @content;
  }
}
@mixin ltSM() {
  @media screen and (max-width: $smBreak) {
    @content;
  }
}
@mixin ltMD() {
  @media screen and (max-width: $mdBreak) {
    @content;
  }
}
@mixin ltLG() {
  @media screen and (max-width: $lgBreak) {
    @content;
  }
}
@mixin ltXL() {
  @content;
}

// ----------------------------------------
// 対象サイズ以上のみ
// ----------------------------------------

@mixin gtXXS() {
  @content;
}
@mixin gtXS() {
  @media screen and (min-width: $xsMinSize) {
    @content;
  }
}
@mixin gtSM() {
  @media screen and (min-width: $smMinSize) {
    @content;
  }
}
@mixin gtMD() {
  @media screen and (min-width: $mdMinSize) {
    @content;
  }
}
@mixin gtLG() {
  @media screen and (min-width: $lgMinSize) {
    @content;
  }
}
@mixin gtXL() {
  @media screen and (min-width: $xlMinSize) {
    @content;
  }
}

// ----------------------------------------
// 対象サイズ範囲内のみ
// ----------------------------------------

@mixin inXXS() {
  @media screen and (max-width:$xxsBreak) {
    @content;
  }
}
@mixin inXS() {
  @media screen and (min-width:$xsMinSize) and (max-width:$xsBreak) {
    @content;
  }
}
@mixin inSM() {
  @media screen and (min-width:$smMinSize) and (max-width:$smBreak) {
    @content;
  }
}
@mixin inMD() {
  @media screen and (min-width:$mdMinSize) and (max-width:$mdBreak) {
    @content;
  }
}
@mixin inLG() {
  @media screen and (min-width:$lgMinSize) and (max-width:$lgBreak) {
    @content;
  }
}
@mixin inXL() {
  @media screen and (min-width:$xlMinSize) {
    @content;
  }
}

// ----------------------------------------
// Viewport の向き
// ----------------------------------------

@mixin landscape() {
  @media screen and (orientation: landscape) {@content;}
}
@mixin portrait() {
  @media screen and (orientation: portrait) {@content;}
}

// ========================================
// Media Queries for Utility
// ========================================

@mixin mq {
  &--ltXXS {@include ltXXS {@content;}}
  &--ltXS {@include ltXS {@content;}}
  &--ltSM {@include ltSM {@content;}}
  &--ltMD {@include ltMD {@content;}}
  &--ltLG {@include ltLG {@content;}}
  &--ltXL {@include ltXL {@content;}}

  &--inXXS {@include inXXS {@content;}}
  &--inXS {@include inXS {@content;}}
  &--inSM {@include inSM {@content;}}
  &--inMD {@include inMD {@content;}}
  &--inLG {@include inLG {@content;}}
  &--inXL {@include inXL {@content;}}

  &--gtXXS {@include gtXXS {@content;}}
  &--gtXS {@include gtXS {@content;}}
  &--gtSM {@include gtSM {@content;}}
  &--gtMD {@include gtMD {@content;}}
  &--gtLG {@include gtLG {@content;}}
  &--gtXL {@include gtXL {@content;}}
}

@mixin mqReverse {
	&--ltXXS {@include gtXS {@content;}}
	&--ltXS {@include gtSM {@content;}}
	&--ltSM {@include gtMD {@content;}}
	&--ltMD {@include gtLG {@content;}}
	&--ltLG {@include gtXL {@content;}}
	&--ltXL {@content;};

	&--inXXS {@include gtXS {@content;}}
	&--inXS {@include ltXXS {@content;};@include gtSM {@content;}}
	&--inSM {@include ltXS {@content;};@include gtMD {@content;}}
	&--inMD {@include ltSM {@content;};@include gtLG {@content;}}
	&--inLG {@include ltMD {@content;};@include gtXL {@content;}}
	&--inXL {@include ltLG {@content;}}

	&--gtXXS {@content;};
	&--gtXS {@include ltXXS {@content;}}
	&--gtSM {@include ltXS {@content;}}
	&--gtMD {@include ltSM {@content;}}
	&--gtLG {@include ltMD {@content;}}
	&--gtXL {@include ltLG {@content;}}
}

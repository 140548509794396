$worryColor: #453c73 !default;

.p-beginners-support {

  &__detail {
    @include pvw(10,10,40);
  }

  &__title {
    @include fsvw(19);
    @include NotoSerif;
    text-align: center;
    color: $accent;
    letter-spacing: .01em;
  }

  &__subtitle {
    @include fsvw(20);
    @include NotoSerif;
    color: $charcoal;
    @include mvw(20,0);
  }

  &__example-box {
    @include flex(ce,sb);
  }

  &__example-baroon {
    width: 64%;
  }

  &__example-image {
    width: 34%;
  }

  &__example-baroontext {
    @include fsvw(13);
    @include pvw(10);
    @include mtvw(20);
    @include NotoSerif;
    background: $truewhite;
    border: 1px solid $accent;
    text-align: center;
    position: relative;
    z-index: 0;

    &::after {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      background: inherit;
      position: absolute;
      z-index: 1;
      top: -10px - 1px;
      left: calc(50% - 10px);
      border: inherit;
      transform: rotate(45deg);
      border-right: none;
      border-bottom: none;
    }

    em {
      color: $accent;
    }
  }

  &__worry {
    background-image: url('../img/beginners/ptn.png');
    background-size: 65px 65px;
    box-shadow: 0 0 10px rgba(#463f77,.2) inset;
    color: $worryColor;
    @include flex(ce,ce);
    @include pvw(30,0,20);
    position: relative;
  }

  &__worry-but {
    position: absolute;
    z-index: 1;
    top: -25vw*.2875;
    left: calc(50% - 37vw*.2875);
    @include wvw(104);

    @include gtSM {
      top: -50px;
      left: calc(50% - 74px);
    };

    @include ltXXS {
      top: -25px;
      left: calc(50% - 37px);
    };

  }

  &__worry-lead {
    width: 100%;
    text-align: center;
    @include fsvw(15);
    @include NotoSerif;
    @include mbvw(10);
  }

  &__worry-list {
    @include fsvw(12);
    max-width: calc(100% - 66px);
    font-weight: 500;
    letter-spacing: 0;
  }

  &__worry-item {

    &::before {
      @include fsvw(10);
      content: '';
      display: inline-block;
      width: .6em;
      height: .9em;
      transform: rotate(45deg);
      border-right: .3em solid Red;
      border-bottom: .3em solid Red;
      margin-right: .8em;
    }
  }

  &__worry-baroon {
    @include wvw(66);
  }

  &__worry-baroon-item {}
}

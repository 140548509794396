.c-button {

  &--stripe {
    @include fsvw(10);
    @include wvw(300);
    @include pvw(6);
    @include mnhvw(30);
    border-radius: 3em;
    margin: 0 auto;
    @include flex(ce,ce);
    background-color: $accent;
    background-image: repeating-linear-gradient(
      -45deg,
      transparent 0,
      transparent 2px,
      rgba($truewhite,.1) 2px,
      rgba($truewhite,.1) 4px
    );
    position: relative;
    box-shadow: 2px 2px 2px rgba($trueblack,.2);

    &::after {
      content: '';
      background: url('../img/common/arrow-handwrite.png');
      @include wvw(18);
      @include hvw(12);
      @include bgsvw(18,12);
      top: 50%;
      @include mvw(-6);
      position: absolute;
      @include rvw(20);
    }

    & > img {
      @include wvw(204);
    }
  }

  &--black {
    // width: 300px;
    // padding: 6px;
    // min-height: 30px;
    @include wvw(300);
    @include pvw(6);
    @include mnhvw(30);
    @include fsvw(12);
    background: $trueblack;
    margin: 0 auto;
    @include flex(ce,ce);
    color: $truewhite;
    @include NotoSerif;
    letter-spacing: .02em;
    position: relative;

    // & > .icon {
    //   @include fsvw(12);
    //   width: .6em;
    //   height: .6em;
    //   position: absolute;
    //   top: calc(50% - .3em);
    //   right: 1em;
    //   border-top: 1px solid $truewhite;
    //   border-right: 1px solid $truewhite;
    //   transform: rotate(45deg);
    //   // @include fsvw(10);
    //   // $borderW: .1em !default;
    //   // $size: .8em !default;
    //   // width: $size;
    //   // min-width: $size;
    //   // height: $size;
    //   // position: absolute;
    //   // top: calc(50% - #{.5*$size});
    //   // right: 12px;
    //   // // background: #f00;
    //   // border-top: $borderW solid currentColor;
    //   // transform: rotate(45deg) scale(1.3);
    //   //
    //   // &::before {
    //   //   content: '';
    //   //   display: block;
    //   //   // width: $borderW;
    //   //   width: $borderW;
    //   //   height: $size - 2*$borderW;
    //   //   // background: currentColor;
    //   //   background: #f00;
    //   //   margin-top: $borderW + $borderW;
    //   //   margin-left: $size - $borderW;
    //   // }
    //
    //
    // }

    & > .glyph {
      position: absolute;
      top: calc(50% - 0);

      &:first-child {
        left: 1em;
      }

      &:last-child {
        right: 1em;
      }
    }
  }

  &--back {
    background-image: url('../img/common/back.png');
    background-repeat: no-repeat;
    background-size: 153px 47px;
    background-position: left center;
  }

  &--grad {
    @include gradbg(rgba($cs01,.6),rgba($cs04,.6));
    width: 100%;
    @include fsvw(16);
    display: block;
    @include pvw(10,0);
    line-height: 1.2;
    text-align: center;
    color: $truewhite;
    font-weight: bold;
    text-shadow: 1px 1px 1px rgba($trueblack,.3);
    box-shadow: .3em .3em 0 rgba($trueblack,.05);

    i {
      font-size: inherit;
      font-weight: normal;
      transform: scale(1.5);
      margin-right: .8em;
      text-shadow: .5px .5px .5px rgba($trueblack,.2);
      position: relative;
      top: -.1em;
    }
  }

}

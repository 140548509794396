.p-index-satisfy-headline {
  @include gradbg;
  text-align: center;
  color: $lightcharcoal;
  font-weight: 300;
  line-height: 1.4;

  &__inner {
    display: block;
    background-image: url('../img/index/bg-title-index-satisfy-headline.png');
    background-color: rgba($truewhite,.4);
    background-position: center center;
    background-repeat: no-repeat;
    @include bgsvw(320,76);
    padding: 10px;
  }

  &__label {
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
    @include fsvw(15.5);
  }

  &__image {}

  &__small {
    display: block;
    @include fsvw(14);
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

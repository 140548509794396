// ========================================
// Gradient
// ========================================

$grad1: $cs01;
$grad2: $cs04;

@mixin gradtypes($color1: $grad1, $color2: $grad2, $start: left) {
  color: $color1;
  background: -webkit-linear-gradient($start,$color1,$color2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin gradbg($color1: rgba($grad1,.4), $color2: rgba($grad2,.4), $to:right) {
  background: linear-gradient(to $to, $color1 0%, $color2 100%);
}

// ========================================
//  IE Hack
// ========================================

@mixin opacity($transparent) {
  opacity: $transparent;
  filter: alpha(opacity= $transparent * 100);
}

@mixin clearfix {
  *zoom: 1;

  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin object-fit($type:cover) {
  object-fit: $type;
  font-family: 'object-fit: #{$type}';
}

// ========================================
// Images
// ========================================

@mixin crisp {
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

@mixin bgs($width,$height) {
  width: $width;
  height: $height;
  background-size: $width $height;
}

// ========================================
// Links & Hover action
// ========================================

@mixin transition($time:.15s) {
  transition: $time all;
}

@mixin ease($time:.15s) {
  transition: $time all ease-in-out;
}

@mixin linear($time:.15s) {
  transition: $time all linear;
}

@mixin hover {
  &:hover {
    @include gtMD { @content; }
  }
}

@mixin external($ct:'\F00B',$family:glyph) {
  &[target] {
    &::after {
      font-size: .8em;
      margin-left: .2em;
      margin-right: .2em;
    }
    @if $family == glyph {
      @include glyph-after($ct,.5);
    } @else if $family == far {
      @include far-after($ct,.5);
    } @else if $family == fab {
      @include fab-after($ct,.5);
    } @else if $family == fas {
      @include fas-after($ct,.5);
    }
  }
}

@mixin ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


// ========================================
// Icons
// ========================================

@mixin icons-base($family) {
  font-family: $family;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: auto;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  font-weight: 400;
}

@mixin far-before($ct,$op:1,$cl:inherit) {
  &::before {
    @include icons-base('Font Awesome 5 Free');
    content: '#{$ct}';
    opacity: $op;
    filter: alpha(opacity= $op * 100);
    color: $cl;
    @content;
  }
}

@mixin far-after($ct,$op:1,$cl:inherit) {
  &::after {
    @include icons-base('Font Awesome 5 Free');
    content: '#{$ct}';
    opacity: $op;
    filter: alpha(opacity= $op * 100);
    color: $cl;
    @content;
  }
}

@mixin fab-before($ct,$op:1,$cl:inherit) {
  &::before {
    @include icons-base('Font Awesome 5 Brands');
    content: '#{$ct}';
    opacity: $op;
    filter: alpha(opacity= $op * 100);
    color: $cl;
    @content;
  }
}

@mixin fab-after($ct,$op:1,$cl:inherit) {
  &::after {
    @include icons-base('Font Awesome 5 Brands');
    content: '#{$ct}';
    opacity: $op;
    filter: alpha(opacity= $op * 100);
    color: $cl;
    @content;
  }
}

@mixin fas-before($ct,$op:1,$cl:inherit) {
  &::before {
    @include icons-base('Font Awesome 5 Free');
    content: '#{$ct}';
    opacity: $op;
    filter: alpha(opacity= $op * 100);
    color: $cl;
    font-weight: 900;
    @content;
  }
}

@mixin fas-after($ct,$op:1,$cl:inherit) {
  &::after {
    @include icons-base('Font Awesome 5 Free');
    content: '#{$ct}';
    opacity: $op;
    filter: alpha(opacity= $op * 100);
    color: $cl;
    font-weight: 900;
    @content;
  }
}

@mixin glyph-before($ct,$op:1,$cl:inherit) {
  &::before {
    @include icons-base('glyphs');
    vertical-align: -.1em;
    content: '#{$ct}';
    opacity: $op;
    filter: alpha(opacity= $op * 100);
    color: $cl;
    @content;
  }
}

@mixin glyph-after($ct,$op:1,$cl:inherit) {
  &::after {
    @include icons-base('glyphs');
    vertical-align: -.1em;
    content: '#{$ct}';
    opacity: $op;
    filter: alpha(opacity= $op * 100);
    color: $cl;
    @content;
  }
}

// ========================================
// Flex Box
// ========================================

@mixin flex($ai:null,$jc:null,$fw:true) {
  display: flex;

  @if $ai {
    @if $ai == ce {
      align-items: center;
    } @else if $ai == fs {
      align-items: flex-start;
    } @else if $ai == fe {
      align-items: flex-end;
    } @else if $ai == st {
      align-items: stretch;
    } @else {
      align-items: $ai;
    }
  }

  @if $jc {
    @if $jc == ce {
      justify-content: center;
    } @else if $jc == fs {
      justify-content: flex-start;
    } @else if $jc == fe {
      justify-content: flex-end;
    } @else if $jc == sa {
      justify-content: space-around;
    } @else if $jc == sb {
      justify-content: space-between;
    } @else {
      justify-content: $jc;
    }
  }

  @if $fw == true {
    flex-wrap: wrap;
  } @else if $fw == false {
    flex-wrap: nowrap;
  } @else {
    justify-content: $fw;
  }
}

// ========================================
// Page Types
// ========================================

@mixin index() {
  .index & { @content; }
}

@mixin lower() {
  .lower & { @content; }
}

@mixin logged-in() {
  .logged-in & { @content; }
}

@mixin admin-bar() {
  .admin-bar & { @content; }
}

// ========================================
// Scroll
// ========================================

@mixin scroll {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin small($fs) {
  @media screen and (max-width: 640px) {
    @include chrome {
      font-size: 10px;
      transform-origin: left center;
      position: relative;
      $rate: .1*$fs !default;
      transform: scale($rate);
      margin-right: 100% - 100%/$rate;
      @content;
    };
  }
}

// ========================================
// Viewport Width size adjust
// ========================================

// @mixin vw($propaty,$px) {
//   #{$propaty}: #{$px}px;
//   #{$propaty}: #{.2875*$px}vw;
//
//   @include gtSM {
//     #{$propaty}: #{1.84*$px}px;
//   };
//
//   @include ltXXXS {
//     #{$propaty}: #{$px}px;
//   };
// }

@mixin vw($propaty,$px1:false,$px2:false,$px3:false,$px4:false) {
  @if $px4 != false {
    // ----------------------------------------
    // 引数が 4 つある場合
    // ----------------------------------------
    @if $px1 == auto and $px2 == auto and $px3 == auto and $px4 == auto {
      // AAAA
      #{$propaty}: auto;
      #{$propaty}: auto;
    } @else if $px1 == auto and $px2 == auto and $px3 == auto {
      // AAA_
      #{$propaty}: auto auto auto #{$px4}px;
      #{$propaty}: auto auto auto #{.2875*$px4}vw;
      @include gtSM {
        #{$propaty}: auto auto auto #{1.84*$px4}px;
      };
      @include ltXXXS {
        #{$propaty}: auto auto auto #{$px4}px;
      };
    } @else if $px1 == auto and $px2 == auto and $px4 == auto {
      // AA_A
      #{$propaty}: auto auto #{$px3}px auto;
      #{$propaty}: auto auto #{.2875*$px3}vw auto;
      @include gtSM {
        #{$propaty}: auto auto #{1.84*$px3}px auto;
      };
      @include ltXXXS {
        #{$propaty}: auto auto #{$px3}px auto;
      };
    } @else if $px1 == auto and $px3 == auto and $px4 == auto {
      // A_AA
      #{$propaty}: auto #{$px2}px auto auto;
      #{$propaty}: auto #{.2875*$px2}vw auto auto;
      @include gtSM {
        #{$propaty}: auto #{1.84*$px2}px auto auto;
      };
      @include ltXXXS {
        #{$propaty}: auto #{$px2}px auto auto;
      };
    } @else if $px2 == auto and $px3 == auto and $px4 == auto {
      // _AAA
      #{$propaty}: #{$px1}px auto auto auto;
      #{$propaty}: #{.2875*$px1}vw auto auto auto;
      @include gtSM {
        #{$propaty}: #{1.84*$px1}px auto auto auto;
      };
      @include ltXXXS {
        #{$propaty}: #{$px1}px auto auto auto;
      };
    } @else if $px1 == auto and $px2 == auto {
      // AA__
      #{$propaty}: auto auto #{$px3}px #{$px4}px;
      #{$propaty}: auto auto #{.2875*$px3}vw #{.2875*$px4}vw;
      @include gtSM {
        #{$propaty}: auto auto #{1.84*$px3}px #{1.84*$px4}px;
      };
      @include ltXXXS {
        #{$propaty}: auto auto #{$px3}px #{$px4}px;
      };
    } @else if $px1 == auto and $px4 == auto {
      // A__A
      #{$propaty}: auto #{$px2}px #{$px3}px auto;
      #{$propaty}: auto #{.2875*$px2}vw #{.2875*$px3}vw auto;
      @include gtSM {
        #{$propaty}: auto #{1.84*$px2}px #{1.84*$px3}px auto;
      };
      @include ltXXXS {
        #{$propaty}: auto #{$px2}px #{$px3}px auto;
      };
    } @else if $px1 == auto and $px3 == auto {
      // A_A_
      #{$propaty}: auto #{$px2}px auto #{$px4}px;
      #{$propaty}: auto #{.2875*$px2}vw auto #{.2875*$px4}vw;
      @include gtSM {
        #{$propaty}: auto #{1.84*$px2}px auto #{1.84*$px4}px;
      };
      @include ltXXXS {
        #{$propaty}: auto #{$px2}px auto #{$px4}px;
      };
    } @else if $px2 == auto and $px4 == auto {
      // _A_A
      #{$propaty}: #{$px1}px auto #{$px3}px auto;
      #{$propaty}: #{.2875*$px1}vw auto #{.2875*$px3}vw auto;
      @include gtSM {
        #{$propaty}: #{1.84*$px1}px auto #{1.84*$px3}px auto;
      };
      @include ltXXXS {
        #{$propaty}: #{$px1}px auto #{$px3}px auto;
      };
    } @else if $px3 == auto and $px4 == auto {
      // __AA
      #{$propaty}: #{$px1}px #{$px2}px auto auto;
      #{$propaty}: #{.2875*$px1}vw #{.2875*$px2}vw auto auto;
      @include gtSM {
        #{$propaty}: #{1.84*$px1}px #{1.84*$px2}px auto auto;
      };
      @include ltXXXS {
        #{$propaty}: #{$px1}px #{$px2}px auto auto;
      };
    } @else if $px2 == auto and $px3 == auto {
      // _AA_
      #{$propaty}: #{$px1}px auto auto #{$px4}px;
      #{$propaty}: #{.2875*$px1}vw auto auto #{.2875*$px4}vw;
      @include gtSM {
        #{$propaty}: #{1.84*$px1}px auto auto #{1.84*$px4}px;
      };
      @include ltXXXS {
        #{$propaty}: #{$px1}px auto auto #{$px4}px;
      };
    } @else if $px1 == auto {
      // A___
      #{$propaty}: auto #{$px2}px #{$px3}px #{$px4}px;
      #{$propaty}: auto #{.2875*$px2}vw #{.2875*$px3}vw #{.2875*$px4}vw;
      @include gtSM {
        #{$propaty}: auto #{1.84*$px2}px #{1.84*$px3}px #{1.84*$px4}px;
      };
      @include ltXXXS {
        #{$propaty}: auto #{$px2}px #{$px3}px #{$px4}px;
      };
    } @else if $px2 == auto {
      // _A__
      #{$propaty}: #{$px1}px auto #{$px3}px #{$px4}px;
      #{$propaty}: #{.2875*$px1}vw auto #{.2875*$px3}vw #{.2875*$px4}vw;
      @include gtSM {
        #{$propaty}: #{1.84*$px1}px auto #{1.84*$px3}px #{1.84*$px4}px;
      };
      @include ltXXXS {
        #{$propaty}: #{$px1}px auto #{$px3}px #{$px4}px;
      };
    } @else if $px3 == auto {
      // __A_
      #{$propaty}: #{$px1}px #{$px2}px auto #{$px4}px;
      #{$propaty}: #{.2875*$px1}vw #{.2875*$px2}vw auto #{.2875*$px4}vw;
      @include gtSM {
        #{$propaty}: #{1.84*$px1}px #{1.84*$px2}px auto #{1.84*$px4}px;
      };
      @include ltXXXS {
        #{$propaty}: #{$px1}px #{$px2}px auto #{$px4}px;
      };
    } @else if $px4 == auto {
      // ___A
      #{$propaty}: #{$px1}px #{$px2}px #{$px3}px auto;
      #{$propaty}: #{.2875*$px1}vw #{.2875*$px2}vw #{.2875*$px3}vw auto;
      @include gtSM {
        #{$propaty}: #{1.84*$px1}px #{1.84*$px2}px #{1.84*$px3}px auto;
      };
      @include ltXXXS {
        #{$propaty}: #{$px1}px #{$px2}px #{$px3}px auto;
      };
    } @else {
      #{$propaty}: #{$px1}px #{$px2}px #{$px3}px #{$px4}px;
      #{$propaty}: #{.2875*$px1}vw #{.2875*$px2}vw #{.2875*$px3}vw #{.2875*$px4}vw;
      @include gtSM {
        #{$propaty}: #{1.84*$px1}px #{1.84*$px2}px #{1.84*$px3}px #{1.84*$px4}px;
      };
      @include ltXXXS {
        #{$propaty}: #{$px1}px #{$px2}px #{$px3}px #{$px4}px;
      };
    }
  } @else if $px3 != false {
    // ----------------------------------------
    // 引数が 3 つある場合
    // ----------------------------------------
    @if $px1 == auto and $px2 == auto and $px3 == auto {
      // AAA
      #{$propaty}: auto;
      #{$propaty}: auto;
    } @else if $px1 == auto and $px2 == auto {
      // AA_
      #{$propaty}: auto auto #{$px3}px;
      #{$propaty}: auto auto #{.2875*$px3}vw;
      @include gtSM {
        #{$propaty}: auto auto #{1.84*$px3}px;
      };
      @include ltXXXS {
        #{$propaty}: auto auto #{$px3}px;
      };
    } @else if $px1 == auto and $px3 == auto {
      // A_A
      #{$propaty}: auto #{$px2}px auto;
      #{$propaty}: auto #{.2875*$px2}vw auto;
      @include gtSM {
        #{$propaty}: auto #{1.84*$px2}px auto;
      };
      @include ltXXXS {
        #{$propaty}: auto #{$px2}px auto;
      };
    } @else if $px2 == auto and $px3 == auto {
      // _AA
      #{$propaty}: #{$px1}px auto auto;
      #{$propaty}: #{.2875*$px1}vw auto auto;
      @include gtSM {
        #{$propaty}: #{1.84*$px1}px auto auto;
      };
      @include ltXXXS {
        #{$propaty}: #{$px1}px auto auto;
      };
    } @else if $px1 == auto {
      // A__
      #{$propaty}: auto #{$px2}px #{$px3}px;
      #{$propaty}: auto #{.2875*$px2}vw #{.2875*$px3}vw;
      @include gtSM {
        #{$propaty}: auto #{1.84*$px2}px #{1.84*$px3}px;
      };
      @include ltXXXS {
        #{$propaty}: auto #{$px2}px #{$px3}px;
      };
    } @else if $px2 == auto {
      // _A_
      #{$propaty}: #{$px1}px auto #{$px3}px;
      #{$propaty}: #{.2875*$px1}vw auto #{.2875*$px3}vw;
      @include gtSM {
        #{$propaty}: #{1.84*$px1}px auto #{1.84*$px3}px;
      };
      @include ltXXXS {
        #{$propaty}: #{$px1}px auto #{$px3}px;
      };
    } @else if $px3 == auto {
      // __A
      #{$propaty}: #{$px1}px #{$px2}px auto;
      #{$propaty}: #{.2875*$px1}vw #{.2875*$px2}vw auto;
      @include gtSM {
        #{$propaty}: #{1.84*$px1}px #{1.84*$px2}px auto;
      };
      @include ltXXXS {
        #{$propaty}: #{$px1}px #{$px2}px auto;
      };
    } @else {
      // ___
      #{$propaty}: #{$px1}px #{$px2}px #{$px3}px;
      #{$propaty}: #{.2875*$px1}vw #{.2875*$px2}vw #{.2875*$px3}vw;
      @include gtSM {
        #{$propaty}: #{1.84*$px1}px #{1.84*$px2}px #{1.84*$px3}px;
      };
      @include ltXXXS {
        #{$propaty}: #{$px1}px #{$px2}px #{$px3}px;
      };
    }
  } @else if $px2 != false {
    // ----------------------------------------
    // 引数が 2 つある場合
    // ----------------------------------------
    @if $px1 == auto and $px2 == auto {
      // AA
      #{$propaty}: auto;
      #{$propaty}: auto;
    } @else if $px1 == auto {
      // A_
      #{$propaty}: auto #{$px2}px;
      #{$propaty}: auto #{.2875*$px2}vw;
      @include gtSM {
        #{$propaty}: auto #{1.84*$px2}px;
      };
      @include ltXXXS {
        #{$propaty}: auto #{$px2}px;
      };
    } @else if $px2 == auto {
      // _A
      #{$propaty}: #{$px1}px auto;
      #{$propaty}: #{.2875*$px1}vw auto;
      @include gtSM {
        #{$propaty}: #{1.84*$px1}px auto;
      };
      @include ltXXXS {
        #{$propaty}: #{$px1}px auto;
      };
    } @else {
      // __
      #{$propaty}: #{$px1}px #{$px2}px;
      #{$propaty}: #{.2875*$px1}vw #{.2875*$px2}vw;
      @include gtSM {
        #{$propaty}: #{1.84*$px1}px #{1.84*$px2}px;
      };
      @include ltXXXS {
        #{$propaty}: #{$px1}px #{$px2}px;
      };
    }
  } @else if $px1 != false {
    // ----------------------------------------
    // 引数が 1 つある場合
    // ----------------------------------------
    @if $px1 == auto {
      // A
      #{$propaty}: auto;
      #{$propaty}: auto;
    } @else {
      // _
      #{$propaty}: #{$px1}px;
      #{$propaty}: #{.2875*$px1}vw;
      @include gtSM {#{$propaty}: #{1.84*$px1}px;};
      @include ltXXXS {#{$propaty}: #{$px1}px;};
    }
  }
}

// ========================================
// font-size
@mixin fsvw($px1:false) {
  @include vw('font-size',$px1);
}
// ========================================

// ========================================
// padding
@mixin pvw($px1:false,$px2:false,$px3:false,$px4:false) {
  @include vw('padding',$px1,$px2,$px3,$px4);
}

// padding-top
@mixin ptvw($px1:false) {
  @include vw('padding-top',$px1);
}

// padding-right
@mixin prvw($px1:false) {
  @include vw('padding-right',$px1);
}

// padding-bottom
@mixin pbvw($px1:false) {
  @include vw('padding-bottom',$px1);
}

// padding-left
@mixin plvw($px1:false) {
  @include vw('padding-left',$px1);
}
// ========================================

// ========================================
// margin
@mixin mvw($px1:false,$px2:false,$px3:false,$px4:false) {
  @include vw('margin',$px1,$px2,$px3,$px4);
}

// margin-top
@mixin mtvw($px1:false) {
  @include vw('margin-top',$px1);
}

// margin-right
@mixin mrvw($px1:false) {
  @include vw('margin-right',$px1);
}

// margin-bottom
@mixin mbvw($px1:false) {
  @include vw('margin-bottom',$px1);
}

// margin-left
@mixin mlvw($px1:false) {
  @include vw('margin-left',$px1);
}
// ========================================

// ========================================
// top
@mixin tvw($px1:false) {
  @include vw('top',$px1);
}

// right
@mixin rvw($px1:false) {
  @include vw('right',$px1);
}

// bottom
@mixin bvw($px1:false) {
  @include vw('bottom',$px1);
}

// left
@mixin lvw($px1:false) {
  @include vw('left',$px1);
}
// ========================================

// ========================================
// width
@mixin wvw($px1:false) {
  @include vw('width',$px1);
}

// height
@mixin hvw($px1:false) {
  @include vw('height',$px1);
}
// ========================================

// ========================================
// max-width
@mixin mxwvw($px1:false) {
  @include vw('max-width',$px1);
}

// max-height
@mixin mxhvw($px1:false) {
  @include vw('max-height',$px1);
}

// min-width
@mixin mnwvw($px1:false) {
  @include vw('min-width',$px1);
}

// min-height
@mixin mnhvw($px1:false) {
  @include vw('min-height',$px1);
}
// ========================================

// ========================================
// background-size
@mixin bgsvw($px1:false,$px2:false) {
  @include vw('background-size',$px1,$px2);
}
// ========================================

// ========================================
// border-width
@mixin bwvw($px1:false,$px2:false,$px3:false,$px4:false) {
  @include vw('border-width',$px1,$px2,$px3,$px4);
}
// ========================================

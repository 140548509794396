.p-beginners-dispatch {
  @include pvw(5,0);
  background: lighten($white,3.6%);

  &__title {
    @include wvw(216);
    @include mvw(13,auto);
  }

  &__image {}

  &__lead {
    @include fsvw(11);
    @include pvw(10);
  }

  &__certificate {
    @include flex(fs,sb);
    @include pvw(10);

    & > img {
      width: 49%;
    }
  }
}

.p-company-detail {
  color: $charcoal;

  &__header {
    @include flex(ce,ce,false);
    @include mtvw(10);
  }

  &__title {
    @include fsvw(18);
    @include NotoSerif;
    line-height: 1.6;
  }

  &__badge {
    @include wvw(78);
    @include mnwvw(78);
    @include hvw(78);
    @include mlvw(20);
  }

  &__text {
    @include fsvw(11);
    @include pvw(10);

    em {
      font-weight: bold;
    }
  }
}

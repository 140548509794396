.p-form-free-dial {
  position: relative;
  @include mvw(5,auto,20);

  &__stripe {
    position: absolute;
    // top: -12px;
    @include tvw(-11);
    left: 0;
    width: 100%;
    z-index: 0;
  }

  &__over-content {
    position: relative;
    z-index: 1;
  }

  &__title {
    @include pvw(10,10,3);
  }

  &__title-strong {
    display: block;
    @include fsvw(14);
    @include NotoSerif;
    color: $charcoal;
    font-weight: bold;
    text-align: center;
  }

  &__call {
    @include flex(ce,ce);
    color: inherit;
    line-height: 1;
    @include prvw(5);
  }

  &__call-icon {
    @include fsvw(36);
    font-weight: normal;
    line-height: 0;
    margin-right: .3em;
    color: $charcoal;
    display: block;
  }

  &__call-number {
    @include fsvw(40);
    @include EB;
    letter-spacing: 0.01em;
    font-weight: normal;
    display: block;
    color: $accent;
  }

  &__time {
    text-align: center;
    @include fsvw(10);
    transform: scale(.9);
    color: $charcoal;
  }

  &__button-container {
    @include pvw(10);
  }
}

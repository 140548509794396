/*
---
name: Flex
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Utility
---

Flex Box のスタイルの指定のユーティリティクラスです。

`.u-flex` に続けて、任意のサフィックスを指定することで `align-items` および `justify-content` の各プロパティがを指定できます。
また、ブレイクポイントのサフィックスも利用できます。

### align-items

`.u-flex--*` (1つ目のサフィックス)に align-items プロパティのサフィックスを追加することにより、任意のプロパティが指定可能です。

- **ce** : center
- **fs** : flex-start
- **fe** : flex-end
- **st** : stretch

```html
<ul class="u-flex--ce">
  <li>List...</li>
  <li>List...<br>text</li>
  <li>List...</li>
</ul>
```

### justify-content

`.u-flex--{ce|fs|fe|st}-*` (2つ目のサフィックス)に justify-content プロパティのサフィックスを追加することにより、任意のプロパティが指定可能です。

- **ce** : center
- **fs** : flex-start
- **fe** : flex-end
- **st** : stretch

```html
<ul class="u-flex--ce-fe">
  <li>List...</li>
  <li>List...<br>text</li>
  <li>List...</li>
</ul>
```

### Media Queries

`.u-flex--*-*--*` (3つ目のサフィックス)にメディアクエリのサフィックスを追加することにより、ブレイクポイントを絞った指定が可能です。（※ ブレイクポイントのサフィックスについては、 [Media Queries](../../Foundation/index.html#Media Queries) を参照してください。）

```html
<ul class="u-flex--fs-fe--gtSM">
  <li>List...</li>
  <li>List...<br>text</li>
  <li>List...</li>
</ul>
```

*/

.u-flex {
  @include flex;
  @include mq { @include flex; }

  &--ce {
    @include flex(ce);
    @include mq { @include flex(ce); }
    &-ce {
      @include flex(ce,ce);
      @include mq { @include flex(ce,ce); }
    }
    &-fs {
      @include flex(ce,fs);
      @include mq { @include flex(ce,fs); }
    }
    &-fe {
      @include flex(ce,fe);
      @include mq { @include flex(ce,fe); }
    }
    &-sa {
      @include flex(ce,sa);
      @include mq { @include flex(ce,sa); }
    }
    &-sb {
      @include flex(ce,sb);
      @include mq { @include flex(ce,sb); }
    }
  }

  &--fs {
    @include flex(fs);
    @include mq { @include flex(fs); }
    &-ce {
      @include flex(fs,ce);
      @include mq { @include flex(fs,ce); }
    }
    &-fs {
      @include flex(fs,fs);
      @include mq { @include flex(fs,fs); }
    }
    &-fe {
      @include flex(fs,fe);
      @include mq { @include flex(fs,fe); }
    }
    &-sa {
      @include flex(fs,sa);
      @include mq { @include flex(fs,sa); }
    }
    &-sb {
      @include flex(fs,sb);
      @include mq { @include flex(fs,sb); }
    }
  }

  &--fe {
    @include flex(fe);
    @include mq { @include flex(fe); }
    &-ce {
      @include flex(fe,ce);
      @include mq { @include flex(fe,ce); }
    }
    &-fs {
      @include flex(fe,fs);
      @include mq { @include flex(fe,fs); }
    }
    &-fe {
      @include flex(fe,fe);
      @include mq { @include flex(fe,fe); }
    }
    &-sa {
      @include flex(fe,sa);
      @include mq { @include flex(fe,sa); }
    }
    &-sb {
      @include flex(fe,sb);
      @include mq { @include flex(fe,sb); }
    }
  }

  &--st {
    @include flex(st);
    @include mq { @include flex(st); }
    &-ce {
      @include flex(st,ce);
      @include mq { @include flex(st,ce); }
    }
    &-fs {
      @include flex(st,fs);
      @include mq { @include flex(st,fs); }
    }
    &-fe {
      @include flex(st,fe);
      @include mq { @include flex(st,fe); }
    }
    &-sa {
      @include flex(st,sa);
      @include mq { @include flex(st,sa); }
    }
    &-sb {
      @include flex(st,sb);
      @include mq { @include flex(st,sb); }
    }
  }
}

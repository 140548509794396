/*
---
name: Preloaders
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Foundation
---
*/

#loading {
  // background-color: rgba(0, 0, 0, 0.82);
  // height: 100%;
  // width: 100%;
  // position: fixed;
  // z-index: 1;
  // margin-top: 0px;
  // top: 0px;
  z-index: 10000 !important;
}

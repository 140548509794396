.p-index-satisfy {
  $this: &;

  &__body {}

  &__item {}

  &__link {
    @include flex(st,fs);
    color: $charcoal;
    line-height: 1.4;

    #{$this}__item:nth-child(2n) & {
      flex-direction: row-reverse;
    }
  }

  &__thumb {
    width: 50%;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 30%;
      height: 100%;
      position: absolute;
      top: 0;
      right: -1px;
      @include gradbg(transparent,$truewhite);

      #{$this}__item:nth-child(2n) & {
        right: auto;
        left: -1px;
        @include gradbg($truewhite,transparent);
      }
    }
  }

  &__detail {
    width: 50%;
    @include flex(ce,fs);
    position: relative;
    @include pvw(15,13);
  }

  &__handwriting {
    position: absolute;
    @include tvw(10);
    @include lvw(10);
    @include wvw(66);
  }

  &__lead {
    @include NotoSerif;
    letter-spacing: 0;
    font-weight: 300;
    @include fsvw(16);
  }

  &__annotation {
    font-size: 9px;
    position: absolute;
    @include fsvw(9);
    @include bvw(20);
    @include lvw(0);
    @include pvw(0,10);
    @include NotoSerif;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 1.2;
  }

  &__more {
    @include bvw(10);
    @include rvw(10);
    @include fsvw(13);
    position: absolute;
    z-index: 2;
    @include gradtypes($cs01,$cs04);
    @include Roboto;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 1;
    @include opacity(.5);

    &::after {
      content: '';
      display: inline-block;
      width: .6em;
      height: .6em;
      border-top: 1px solid $cs04;
      border-right: 1px solid $cs04;
      transform: rotate(45deg);
      margin-left: .3em;
    }
  }
}

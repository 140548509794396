// ========================================
// Include third-party styles.
// ========================================

@import "//fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700,900";
@import "//fonts.googleapis.com/css?family=Noto+Serif+JP:200,300,400,500,600,700,900";
@import "//fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i";
@import "//fonts.googleapis.com/css?family=Roboto+Slab:100,300,400,700";
@import "//fonts.googleapis.com/css?family=EB+Garamond:400,400i,500,500i,600,600i,700,700i,800,800i";

// ========================================
// Foundation
// ========================================

@import "foundation/colors";
@import "foundation/typography";
@import "foundation/normalize";
@import "foundation/media-queries";
@import "foundation/user-agent";
@import "foundation/mixins";
@import "foundation/base";
@import "foundation/fontawesome";
@import "foundation/slick";
@import "foundation/preloaders";
@import "foundation/animate";
@import "foundation/aigis-glyph";

// ========================================
// Layout
// ========================================

@import "layout/header";
@import "layout/footer";
@import "layout/footer-navigation";
@import "layout/footer-free-dial";
@import "layout/footer-button-block";
@import "layout/navigation";
@import "layout/main";

// ========================================
// Object
// ========================================

@import "object/component/_breadcrumb.scss";
@import "object/component/_button.scss";
@import "object/component/_carousel.scss";
@import "object/component/_collapse.scss";
@import "object/component/_container.scss";
@import "object/component/_content.scss";
@import "object/component/_copyright.scss";
@import "object/component/_dialog.scss";
@import "object/component/_editor-content.scss";
@import "object/component/_entire-overlay.scss";
@import "object/component/_grid.scss";
@import "object/component/_hero-image.scss";
@import "object/component/_logo.scss";
@import "object/component/_media.scss";
@import "object/component/_modal.scss";
@import "object/component/_nav-target.scss";
@import "object/component/_nav-trigger.scss";
@import "object/component/_navbelow.scss";
@import "object/component/_page-header.scss";
@import "object/component/_pagenavi.scss";
@import "object/component/_pagetop-button.scss";
@import "object/component/_post-archive.scss";
@import "object/component/_row.scss";
@import "object/component/_scroll-box.scss";
@import "object/component/_siteid.scss";
@import "object/component/_tab.scss";
@import "object/project/_archive-title.scss";
@import "object/project/_article-calendar.scss";
@import "object/project/_article-category.scss";
@import "object/project/_article-header.scss";
@import "object/project/_article-meta-profile.scss";
@import "object/project/_article-meta.scss";
@import "object/project/_article-post.scss";
@import "object/project/_article-social.scss";
@import "object/project/_article.scss";
@import "object/project/_blog-page-navi-container.scss";
@import "object/project/_bottom-fix.scss";
@import "object/project/_comp.scss";
@import "object/project/_cover.scss";
@import "object/project/_demo.scss";
@import "object/project/_entryflow-bringing.scss";
@import "object/project/_entryflow.scss";
@import "object/project/_form-free-dial.scss";
@import "object/project/_hairset-gallery.scss";
@import "object/project/_hairset-movie.scss";
@import "object/project/_incite.scss";
@import "object/project/_jobset.scss";
@import "object/project/_overlay-stripe.scss";
@import "object/project/_pursuit.scss";
@import "object/project/_ribbon-black.scss";
@import "object/project/_ribbon-label.scss";
@import "object/project/_ribbon-pink.scss";
@import "object/project/_search-title.scss";
@import "object/project/_search.scss";
@import "object/project/_strategy.scss";
@import "object/project/_stripe-hr.scss";
@import "object/project/_stripe-title.scss";
@import "object/project/_workflow.scss";
@import "object/project/beginners/_beginners-dispatch.scss";
@import "object/project/beginners/_beginners-feature.scss";
@import "object/project/beginners/_beginners-message.scss";
@import "object/project/beginners/_beginners-relief.scss";
@import "object/project/beginners/_beginners-support.scss";
@import "object/project/beginners/_beginners-workstyle.scss";
@import "object/project/company/_company-detail.scss";
@import "object/project/company/_company-privacy.scss";
@import "object/project/company/_company-profile.scss";
@import "object/project/company/_company-staff.scss";
@import "object/project/faq/_faq.scss";
@import "object/project/feature/_feature-happypoint.scss";
@import "object/project/feature/_feature-image.scss";
@import "object/project/feature/_feature-introduce.scss";
@import "object/project/feature/_feature-salary-example.scss";
@import "object/project/feature/_feature.scss";
@import "object/project/for-employer/_for-employer.scss";
@import "object/project/for-job-seeker/_for-job-seeker.scss";
@import "object/project/index/_index-erika-blog.scss";
@import "object/project/index/_index-feedback.scss";
@import "object/project/index/_index-for-beginners.scss";
@import "object/project/index/_index-insta.scss";
@import "object/project/index/_index-keyvisual.scss";
@import "object/project/index/_index-office-view.scss";
@import "object/project/index/_index-owners-blog.scss";
@import "object/project/index/_index-reason-satisfy.scss";
@import "object/project/index/_index-satisfy-headline.scss";
@import "object/project/index/_index-satisfy.scss";
@import "object/project/mens-recruit/_mens-recruit-requirements.scss";
@import "object/project/mens-recruit/_mens-recruit.scss";
@import "object/project/place/_contact-table.scss";
@import "object/project/place/_feedback.scss";
@import "object/project/place/_form.scss";
@import "object/project/place/_place-archive.scss";
@import "object/project/place/_place-detail.scss";
@import "object/utility/_align.scss";
@import "object/utility/_animate_mod.scss";
@import "object/utility/_cft.scss";
@import "object/utility/_clearfix.scss";
@import "object/utility/_colors.scss";
@import "object/utility/_ellipsis.scss";
@import "object/utility/_flex.scss";
@import "object/utility/_fluid-button.scss";
@import "object/utility/_font-style.scss";
@import "object/utility/_fs.scss";
@import "object/utility/_glyph.scss";
@import "object/utility/_heading.scss";
@import "object/utility/_hidden.scss";
@import "object/utility/_margin.scss";
@import "object/utility/_objectFit.scss";
@import "object/utility/_position.scss";
@import "object/utility/_screen-reader-text.scss";
@import "object/utility/_size.scss";
@import "object/utility/_text.scss";
@import "object/utility/_typography.scss";
@import "object/utility/_visible.scss";

// ========================================
// Browser Sync Dialog
// ========================================

#__bs_notify__ {
  display: none !important;
}

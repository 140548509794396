/*
---
name: Animate Modified
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Utility
---

*/

@include gtMD {
  *[data-animated] {
    opacity: 0;

    &.is-inview {
      opacity: 1;
    }
  }
  //
  // &[data-animated="flip"] {
  // 	transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
  // }
  //
  // &[data-animated="flipInX"] {
  // 	transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
  // }
  //
  // &[data-animated="flipInY"] {
  // 	transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
  // }
};

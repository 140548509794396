.p-feature-image {
  width: 100%;
  max-width: 70vh;
  height: 0;
  @include flex(ce,ce);

  & > img {
    width: 100%;
  }
}

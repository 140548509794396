.p-for-employer {
  color: $charcoal;

  &--support {
    @include pvw(20,10);
  }

  &--relief {
    background: #fbfbfb;
    @include pvw(10,10);
  }

  &__title {
    @include fsvw(18);
    @include NotoSerif;
    font-weight: 600;
    text-align: justify;
    letter-spacing: .01em;
    line-height: 1.4;
  }

  &__tel-wrapper {
    @include pvw(10,10);
  }

  &__tel {
    @include fsvw(10);
    display: block;
    width: 100%;
    @include mvw(15,0);
    box-shadow: .5em .5em 0 rgba($trueblack,.04);
  }

  &__lead {
    @include fsvw(11);
  }

  &__subtitle {
    @include fsvw(18);
    @include NotoSerif;
    color: $truewhite;
    position: relative;
    padding: 0.6em 0;
    // @include gradbg;
    background: -webkit-repeating-linear-gradient(-45deg, #f17fb9, #f17fb9 4px,#f392c3 3px, #f392c3 8px);
    background: repeating-linear-gradient(-45deg, #f17fb9, #f17fb9 4px,#f392c3 3px, #f392c3 8px);
    font-weight: 600;
    text-align: center;
    letter-spacing: .01em;
    line-height: 1.4em;
    box-shadow: 0 .25em $lightsilver;
    margin-bottom: 30px;
    position: relative;
    z-index: 1;

    &::before,
    &::after {
      position: absolute;
      content: '';
      top: calc(100% - 1px);
      left: 45%;
      border: 8px solid transparent;
      border-top: 15px solid #f37db9;
      width: 0;
      height: 0;
    }

    &::before {
      margin-top: .25em;
      border-top-color: $lightsilver;
    }
  }

  &__list-wrap {
    position: relative;
    @include pbvw(50);
    position: relative;

    &::before {
      content: '';
      display: block;
      top: 0;
      left: -10%;
      right: -10%;
      @include bvw(50);
      position: absolute;
      background: $truewhite;
    }

    &::after {
      content: '';
      display: block;
      left: -10%;
      right: -10%;
      bottom: 1px;
      @include hvw(50);
      position: absolute;
      background: red;
      @include gradbg($truewhite,rgba($truewhite,0),bottom);
    }
  }

  &__list {
    position: relative;

    &::after {
      content: '';
      display: block;
      left: -10%;
      right: -10%;
      @include tvw(-50);
      margin-top: 1px;
      @include hvw(50);
      position: absolute;
      background: red;
      @include gradbg($truewhite,rgba($truewhite,0),top);
    }
  }

  &__list-item {
    @include fsvw(13);
    @include NotoSerif;
    font-weight: 600;
    // @include flex(fs,fs);
    position: relative;
    padding-left: 1.5em;
    line-height: 1.4;
    margin: .8em 0;
    letter-spacing: .02em;
    z-index: 1;

    &::before {
      content: '';
      display: block;
      // display: inline-block;
      width: 1em;
      height: 1em;
      border: 1px solid rgba($charcoal,.5);
      margin-right: .5em;
      position: absolute;
      top: .2em;
      left: 0;
    }

    &::after {
      content: '';
      display: block;
      width: .35em;
      height: .7em;
      border-top: 4px solid Red;
      border-left: 4px solid Red;
      transform: rotate(-135deg);
      margin-right: .5em;
      position: absolute;
      top: .16em;
      left: .4em;
      @include bwvw(3,0,0,3);
    }
  }

  &__list-image {
    position: absolute;
    bottom: 0;
    right: 0;
    @include wvw(95);
    z-index: 1;
  }

  &__closing {
    @include gradbg;
    position: relative;
    @include pvw(10);
    line-height: 1.4;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      @include gradbg(#fbfbfb,rgba(#fbfbfb,.5),bottom);
      z-index: 0;
    }
  }

  &__closing-text {
    @include pvw(10);
    @include fsvw(18);
    @include NotoSerif;
    color: $accent;
    position: relative;
    z-index: 1;
    letter-spacing: 0;
    text-align: justify;
  }
}

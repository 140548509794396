.p-article-meta {
  background: $white;

  &__body {
    @include pvw(10,0);
    border-bottom: 1px solid rgba($charcoal,.1);
  }

  &__label {
    @include mvw(10);
  }

  &__label-inner {
    @include wvw(120);
  }

  &__profile {}

  &__recent-post {
    @include fsvw(11);
    @include pvw(0,15);

    a {
      @include pvw(2,0);
      color: $charcoal;
      font-weight: 400;
      @include flex(ce,fs,false);

      time {
        display: block;
        color: $accent;
        @include fsvw(10);
        min-width: 8em;
      }

      span {
        display: block;
        @include ellipsis;
      }
    }
  }

  &__categories {
    @include fsvw(11);
    @include pvw(0,15);

    li {
      border-bottom: 1px solid rgba($charcoal,.1);
    }

    a {
      @include pvw(2,0);
      color: $charcoal;
      font-weight: 400;
      @include flex(ce,fs,false);

      &::before {
        content: '';
        display: block;
        width: .3em;
        min-width: .3em;
        height: .3em;
        border-top: 2px solid $accent;
        border-right: 2px solid $accent;
        transform: rotate(45deg);
        margin-right: 1em;
      }
    }
  }

  &__archive-month {
    @include pvw(0,15);
  }

  &__archive-month-select {
    @include fsvw(11);
    border: 1px solid $silver;
    border-radius: .4em;
    @include pvw(4,20,4,10);
    background: $truewhite;
    cursor: pointer;
    -webkit-appearance: button;
    -moz-appearance: button;
    font-family: inherit;
    display: inline-block;
    width: 100%;
    @include hvw(30);
    background-image: url('../img/common/bg-select.png');
    @include bgsvw(11,50);
    background-repeat: no-repeat;
    background-position: right center;
  }

  &__search {
    @include pvw(15);
    @include flex(ce,sb);
  }

  &__search-label {
    width: 30%;
    text-align: center;
    @include flex(ce,ce);
    font-weight: bold;
    @include fsvw(12);
    color: $black;
    text-transform: uppercase;
    @include Roboto;
  }

  &__search-item {
    @include fsvw(11);
    border: 1px solid $silver;
    border-radius: .4em;
    @include pvw(4,20,4,10);
    background: $truewhite;
    cursor: pointer;
    -webkit-appearance: button;
    -moz-appearance: button;
    font-family: inherit;
    display: inline-block;
    width: 65%;
    @include hvw(30);
  }

  &__search-form {
    @include fsvw(11);
    width: 65%;
    @include hvw(30);
    position: relative;
  }

  &__search-input {
    border: 1px solid $silver;
    @include pvw(4,30,4,10);
    border-radius: .4em;
    background: $truewhite;
    cursor: pointer;
    -webkit-appearance: button;
    -moz-appearance: button;
    font-family: inherit;
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  &__search-button {
    @include fsvw(11);
    position: absolute;
    top: 0;
    bottom: 0;
    right: .5em;
    background: transparent;
    border: none;
    color: $charcoal;
    @include opacity(.5);
  }
}

// .yuzo_related_post {
//   .yuzo_clearfixed.yuzo__title {
//     display: none;
//   }
//
//   .yuzo_wraps {
//     @include flex(ce,ce);
//     @include pvw(5);
//
//     .relatedthumb {
//       float: none !important;
//       width: 25% !important;
//       @include pvw(2);
//
//       &:hover {
//         background: none !important;
//       }
//
//       a {
//         @include flex(fs,ce);
//         font-weight: 500;
//
//         .yuzo-img-wrap {
//           @include wvw(78);
//           @include hvw(78);
//
//           .yuzo-img {
//             @include mxwvw(75);
//             @include mxhvw(75);
//           }
//         }
//
//         .yuzo__text--title {
//           @include fsvw(10);
//           color: $charcoal;
//           margin-bottom: .5em;
//         }
//
//         .yuzo_icon_views {
//           @include fsvw(10);
//           color: $accent;
//           @include opacity(.8);
//           font-weight: bold;
//         }
//       }
//     }
//   }
// }

.p-overlay-stripe {
  position: relative;

  &__stripe {
    display: block;

    &::before,
    &::after {
      content: '';
      display: block;
      width: 140%;
      margin-left: -20%;
      margin-right: -20%;
      height: 8.625vw;
      background-color: transparent;
      background-image: repeating-linear-gradient(
        -45deg,
        transparent 0,
        transparent 4px,
        rgba($trueblack,1) 4px,
        rgba($trueblack,1) 8px
      );
      mix-blend-mode: overlay;
      position: absolute;
      top: calc(50% - 4.3125vw);
      left: 0;
      transform: rotate(3deg);

      @include gtSM {
        height: 60px;
        top: calc(50% - 30px);
      };

      @include ltXXXS {
        height: 30px;
        top: calc(50% - 15px);
      };
    }
  }

  &__text {
    display: block;
    text-align: center;
    position: relative;
  }
}

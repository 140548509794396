/*
---
name: Object Fit
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Utility
---

`object-fit:` プロパティを指定するユーティリティクラスです。`object-fit:` プロパティの IE・Edge 前方互換対応が JS によって行われます。

```html
<img src="http://placehold.jp/3d4070/ffffff/320x320.png" style="width:160px;height:80px;">

<img class="u-object-fit" src="http://placehold.jp/3d4070/ffffff/320x320.png" style="width:160px;height:80px;">
```
```jade
//- Pug
img(src="http://placehold.jp/3d4070/ffffff/320x320.png" style="width:160px;height:80px;")

img.u-object-fit(src="http://placehold.jp/3d4070/ffffff/320x320.png" style="width:160px;height:80px;")
```

`.u-object-fit` に続けて、 `object-fit:` プロパティで指定できる値（ `cover` , `contain` , `fill` , `none` , `scale-down` ）をサフィックスとして指定することでプロパティの値を指定することが可能です。<br>
※ 何も指定しない場合は `cover` が指定されます。

```html
<img class="u-object-fit--cover" src="http://placehold.jp/3d4070/ffffff/320x320.png" style="width:160px;height:80px;">

<img class="u-object-fit--contain" src="http://placehold.jp/3d4070/ffffff/320x320.png" style="width:160px;height:80px;">

<img class="u-object-fit--fill" src="http://placehold.jp/3d4070/ffffff/320x320.png" style="width:160px;height:80px;">

<img class="u-object-fit--none" src="http://placehold.jp/3d4070/ffffff/320x320.png" style="width:160px;height:80px;">

<img class="u-object-fit--scale-down" src="http://placehold.jp/3d4070/ffffff/320x320.png" style="width:160px;height:80px;">
```
```jade
//- Pug
img.u-object-fit--cover(src="http://placehold.jp/3d4070/ffffff/320x320.png" style="width:160px;height:80px;")

img.u-object-fit--contain(src="http://placehold.jp/3d4070/ffffff/320x320.png" style="width:160px;height:80px;")

img.u-object-fit--fill(src="http://placehold.jp/3d4070/ffffff/320x320.png" style="width:160px;height:80px;")

img.u-object-fit--none(src="http://placehold.jp/3d4070/ffffff/320x320.png" style="width:160px;height:80px;")

img.u-object-fit--scale-down(src="http://placehold.jp/3d4070/ffffff/320x320.png" style="width:160px;height:80px;")
```


*/

.u-object-fit {
  @include object-fit;

  &--cover { @include object-fit(cover) };

  &--contain { @include object-fit(contain) };

  &--fill { @include object-fit(fill) };

  &--none { @include object-fit(none) };

  &--scale-down { @include object-fit(scale-down) };
}

// --------------------------------------------------
// User defined colors
// --------------------------------------------------

.u {
  &-cs01          {color:          $cs01 !important;}
  &-cs01Lt        {color:        $cs01Lt !important;}
  &-cs01Dk        {color:        $cs01Dk !important;}
  &-cs01St        {color:        $cs01St !important;}
  &-cs01Ds        {color:        $cs01Ds !important;}
  &-cs02          {color:          $cs02 !important;}
  &-cs02Lt        {color:        $cs02Lt !important;}
  &-cs02Dk        {color:        $cs02Dk !important;}
  &-cs02St        {color:        $cs02St !important;}
  &-cs02Ds        {color:        $cs02Ds !important;}
  &-cs03          {color:          $cs03 !important;}
  &-cs03Lt        {color:        $cs03Lt !important;}
  &-cs03Dk        {color:        $cs03Dk !important;}
  &-cs03St        {color:        $cs03St !important;}
  &-cs03Ds        {color:        $cs03Ds !important;}
  &-cs04          {color:          $cs04 !important;}
  &-cs04Lt        {color:        $cs04Lt !important;}
  &-cs04Dk        {color:        $cs04Dk !important;}
  &-cs04St        {color:        $cs04St !important;}
  &-cs04Ds        {color:        $cs04Ds !important;}
  &-base          {color:          $base !important;}
  &-baseLt        {color:        $baseLt !important;}
  &-baseDk        {color:        $baseDk !important;}
  &-baseSt        {color:        $baseSt !important;}
  &-baseDs        {color:        $baseDs !important;}
  &-main          {color:          $main !important;}
  &-mainLt        {color:        $mainLt !important;}
  &-mainDk        {color:        $mainDk !important;}
  &-mainSt        {color:        $mainSt !important;}
  &-mainDs        {color:        $mainDs !important;}
  &-accent        {color:        $accent !important;}
  &-accentLt      {color:      $accentLt !important;}
  &-accentDk      {color:      $accentDk !important;}
  &-accentSt      {color:      $accentSt !important;}
  &-accentDs      {color:      $accentDs !important;}
  &-standard-gray {color: $standard-gray !important;}
  &-blue-gray     {color:     $blue-gray !important;}
  &-brown-gray    {color:    $brown-gray !important;}
  &-grey-base     {color:     $grey-base !important;}
  &-truewhite     {color:     $truewhite !important;}
  &-white         {color:         $white !important;}
  &-lightsilver   {color:   $lightsilver !important;}
  &-silver        {color:        $silver !important;}
  &-darksilver    {color:    $darksilver !important;}
  &-lightgrey     {color:     $lightgrey !important;}
  &-grey          {color:          $grey !important;}
  &-darkgrey      {color:      $darkgrey !important;}
  &-lightcharcoal {color: $lightcharcoal !important;}
  &-charcoal      {color:      $charcoal !important;}
  &-darkcharcoal  {color:  $darkcharcoal !important;}
  &-black         {color:         $black !important;}
  &-trueblack     {color:     $trueblack !important;}
}

.u {
  &-bg-cs01          {background-color:          $cs01 !important;}
  &-bg-cs01Lt        {background-color:        $cs01Lt !important;}
  &-bg-cs01Dk        {background-color:        $cs01Dk !important;}
  &-bg-cs01St        {background-color:        $cs01St !important;}
  &-bg-cs01Ds        {background-color:        $cs01Ds !important;}
  &-bg-cs02          {background-color:          $cs02 !important;}
  &-bg-cs02Lt        {background-color:        $cs02Lt !important;}
  &-bg-cs02Dk        {background-color:        $cs02Dk !important;}
  &-bg-cs02St        {background-color:        $cs02St !important;}
  &-bg-cs02Ds        {background-color:        $cs02Ds !important;}
  &-bg-cs03          {background-color:          $cs03 !important;}
  &-bg-cs03Lt        {background-color:        $cs03Lt !important;}
  &-bg-cs03Dk        {background-color:        $cs03Dk !important;}
  &-bg-cs03St        {background-color:        $cs03St !important;}
  &-bg-cs03Ds        {background-color:        $cs03Ds !important;}
  &-bg-cs04          {background-color:          $cs04 !important;}
  &-bg-cs04Lt        {background-color:        $cs04Lt !important;}
  &-bg-cs04Dk        {background-color:        $cs04Dk !important;}
  &-bg-cs04St        {background-color:        $cs04St !important;}
  &-bg-cs04Ds        {background-color:        $cs04Ds !important;}
  &-bg-base          {background-color:          $base !important;}
  &-bg-baseLt        {background-color:        $baseLt !important;}
  &-bg-baseDk        {background-color:        $baseDk !important;}
  &-bg-baseSt        {background-color:        $baseSt !important;}
  &-bg-baseDs        {background-color:        $baseDs !important;}
  &-bg-main          {background-color:          $main !important;}
  &-bg-mainLt        {background-color:        $mainLt !important;}
  &-bg-mainDk        {background-color:        $mainDk !important;}
  &-bg-mainSt        {background-color:        $mainSt !important;}
  &-bg-mainDs        {background-color:        $mainDs !important;}
  &-bg-accent        {background-color:        $accent !important;}
  &-bg-accentLt      {background-color:      $accentLt !important;}
  &-bg-accentDk      {background-color:      $accentDk !important;}
  &-bg-accentSt      {background-color:      $accentSt !important;}
  &-bg-accentDs      {background-color:      $accentDs !important;}
  &-bg-standard-gray {background-color: $standard-gray !important;}
  &-bg-blue-gray     {background-color:     $blue-gray !important;}
  &-bg-brown-gray    {background-color:    $brown-gray !important;}
  &-bg-grey-base     {background-color:     $grey-base !important;}
  &-bg-truewhite     {background-color:     $truewhite !important;}
  &-bg-white         {background-color:         $white !important;}
  &-bg-lightsilver   {background-color:   $lightsilver !important;}
  &-bg-silver        {background-color:        $silver !important;}
  &-bg-darksilver    {background-color:    $darksilver !important;}
  &-bg-lightgrey     {background-color:     $lightgrey !important;}
  &-bg-grey          {background-color:          $grey !important;}
  &-bg-darkgrey      {background-color:      $darkgrey !important;}
  &-bg-lightcharcoal {background-color: $lightcharcoal !important;}
  &-bg-charcoal      {background-color:      $charcoal !important;}
  &-bg-darkcharcoal  {background-color:  $darkcharcoal !important;}
  &-bg-black         {background-color:         $black !important;}
  &-bg-trueblack     {background-color:     $trueblack !important;}
}

// --------------------------------------------------
// web safe colors
// --------------------------------------------------

$u-colors: (
  -LightPink:            #FFB6C1,
  -Pink:                 #FFC0CB,
  -Crimson:              #DC143C,
  -LavenderBlush:        #FFF0F5,
  -PaleVioletRed:        #DB7093,
  -HotPink:              #FF69B4,
  -DeepPink:             #FF1493,
  -MediumVioletRed:      #C71585,
  -Orchid:               #DA70D6,
  -Thistle:              #D8BFD8,
  -Plum:                 #DDA0DD,
  -Violet:               #EE82EE,
  -Magenta:              #FF00FF,
  -Fuchsia:              #FF00FF,
  -DarkMagenta:          #8B008B,
  -Purple:               #800080,
  -MediumOrchid:         #BA55D3,
  -DarkViolet:           #9400D3,
  -DarkOrchid:           #9932CC,
  -Indigo:               #4B0082,
  -BlueViolet:           #8A2BE2,
  -MediumPurple:         #9370DB,
  -MediumSlateBlue:      #7B68EE,
  -SlateBlue:            #6A5ACD,
  -DarkSlateBlue:        #483D8B,
  -Lavender:             #E6E6FA,
  -GhostWhite:           #F8F8FF,
  -Blue:                 #0000FF,
  -MediumBlue:           #0000CD,
  -MidnightBlue:         #191970,
  -DarkBlue:             #00008B,
  -Navy:                 #000080,
  -RoyalBlue:            #4169E1,
  -CornflowerBlue:       #6495ED,
  -LightSteelBlue:       #B0C4DE,
  -LightSlateGray:       #778899,
  -SlateGray:            #708090,
  -DodgerBlue:           #1E90FF,
  -AliceBlue:            #F0F8FF,
  -SteelBlue:            #4682B4,
  -LightSkyBlue:         #87CEFA,
  -SkyBlue:              #87CEEB,
  -DeepSkyBlue:          #00BFFF,
  -LightBlue:            #ADD8E6,
  -PowderBlue:           #B0E0E6,
  -CadetBlue:            #5F9EA0,
  -Azure:                #F0FFFF,
  -LightCyan:            #E0FFFF,
  -PaleTurquoise:        #AFEEEE,
  -Cyan:                 #00FFFF,
  -Aqua:                 #00FFFF,
  -DarkTurquoise:        #00CED1,
  -DarkSlateGray:        #2F4F4F,
  -DarkCyan:             #008B8B,
  -Teal:                 #008080,
  -MediumTurquoise:      #48D1CC,
  -LightSeaGreen:        #20B2AA,
  -Turquoise:            #40E0D0,
  -Aquamarine:           #7FFFD4,
  -MediumAquamarine:     #66CDAA,
  -MediumSpringGreen:    #00FA9A,
  -MintCream:            #F5FFFA,
  -SpringGreen:          #00FF7F,
  -MediumSeaGreen:       #3CB371,
  -SeaGreen:             #2E8B57,
  -Honeydew:             #F0FFF0,
  -LightGreen:           #90EE90,
  -PaleGreen:            #98FB98,
  -DarkSeaGreen:         #8FBC8F,
  -LimeGreen:            #32CD32,
  -Lime:                 #00FF00,
  -ForestGreen:          #228B22,
  -Green:                #008000,
  -DarkGreen:            #006400,
  -Chartreuse:           #7FFF00,
  -LawnGreen:            #7CFC00,
  -GreenYellow:          #ADFF2F,
  -DarkOliveGreen:       #556B2F,
  -YellowGreen:          #9ACD32,
  -OliveDrab:            #6B8E23,
  -Beige:                #F5F5DC,
  -LightGoldenrodYellow: #FAFAD2,
  -Ivory:                #FFFFF0,
  -LightYellow:          #FFFFE0,
  -Yellow:               #FFFF00,
  -Olive:                #808000,
  -DarkKhaki:            #BDB76B,
  -LemonChiffon:         #FFFACD,
  -PaleGoldenrod:        #EEE8AA,
  -Khaki:                #F0E68C,
  -Gold:                 #FFD700,
  -Cornsilk:             #FFF8DC,
  -Goldenrod:            #DAA520,
  -DarkGoldenrod:        #B8860B,
  -FloralWhite:          #FFFAF0,
  -OldLace:              #FDF5E6,
  -Wheat:                #F5DEB3,
  -Moccasin:             #FFE4B5,
  -Orange:               #FFA500,
  -PapayaWhip:           #FFEFD5,
  -BlanchedAlmond:       #FFEBCD,
  -NavajoWhite:          #FFDEAD,
  -AntiqueWhite:         #FAEBD7,
  -Tan:                  #D2B48C,
  -BurlyWood:            #DEB887,
  -Bisque:               #FFE4C4,
  -DarkOrange:           #FF8C00,
  -Linen:                #FAF0E6,
  -Peru:                 #CD853F,
  -PeachPuff:            #FFDAB9,
  -SandyBrown:           #F4A460,
  -Chocolate:            #D2691E,
  -SaddleBrown:          #8B4513,
  -Seashell:             #FFF5EE,
  -Sienna:               #A0522D,
  -LightSalmon:          #FFA07A,
  -Coral:                #FF7F50,
  -OrangeRed:            #FF4500,
  -DarkSalmon:           #E9967A,
  -Tomato:               #FF6347,
  -MistyRose:            #FFE4E1,
  -Salmon:               #FA8072,
  -Snow:                 #FFFAFA,
  -LightCoral:           #F08080,
  -RosyBrown:            #BC8F8F,
  -IndianRed:            #CD5C5C,
  -Red:                  #FF0000,
  -Brown:                #A52A2A,
  -FireBrick:            #B22222,
  -DarkRed:              #8B0000,
  -Maroon:               #800000,
  -White:                #FFFFFF,
  -WhiteSmoke:           #F5F5F5,
  -Gainsboro:            #DCDCDC,
  -LightGrey:            #D3D3D3,
  -Silver:               #C0C0C0,
  -DarkGray:             #A9A9A9,
  -Gray:                 #808080,
  -DimGray:              #696969,
  -Black:                #000000,
);

@each $key, $color in $u-colors {
  .u#{$key} {
    color: $color;
  }
}

@each $key, $color in $u-colors {
  .u-bg#{$key} {
    background-color: $color;
  }
}

// --------------------------------------------------
// Optional
// --------------------------------------------------

.u {
  &-transparent {
    color: transparent !important;
  }

  &-grad {
    @include gradtypes;
  }
}

.u-bg {
  &-transparent {
    background-color: transparent !important;
  }

  &-grad {
    @include gradbg;
  }

  &-grad--light {
    @include gradbg(rgba($cs01,.1),rgba($cs04,.1));
  }
}
